import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Badge } from '@saatva-bits/pattern-library.components.badge'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'
import { useGetDiscounts, usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import styles from './ProductPrice.module.scss'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { MAP_PROMO_COLOR_TO_BADGE } from '@/constants/colors'
import { CATEGORIES } from '@/temp-configs/category-constants'

const ProductPrice = ({
    productCode,
    className,
}) => {
    const { getDiscounts } = useGetDiscounts()
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent } = sitewidePromo

    const bannerColor = sitewidePromo?.theme
    const badgeColor = bannerColor && MAP_PROMO_COLOR_TO_BADGE[bannerColor] ? MAP_PROMO_COLOR_TO_BADGE[bannerColor] : MAP_PROMO_COLOR_TO_BADGE.blue
 
    // get the state of the primary product
    const primaryProductState = useProductState(productCode, ['category', 'inStock', 'quantity', 'price', 'sku', 'bundledVariants', 'productCode'])

    // if the primary product is a bundle, get the data for each product variant included in the bundle
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)

    // if the primary product is a bundle, use the bundled variants to calculate the discounts, otherwise use the primary product
    const variantsForDiscounts = bundledVariants.length ? [...bundledVariants] : [primaryProductState]

    const productInfoForDiscount = variantsForDiscounts.map(variant => {
        return {
            category: variant.category.toLowerCase(),
            isInStock: variant.inStock,
            isPrimaryProduct: variant.isPrimaryProduct || true,
            parentSku: variant.productCode,
            price: variant.price,
            quantity: variant.quantity || 1,
            sku: variant.sku
        }
    })

    let {
        fullPrice,
        finalPrice,
        discountAmount
    } = getDiscounts(productInfoForDiscount)

    // temporarily handling bundle discounts.
    if (primaryProductState.category.toLowerCase() === CATEGORIES.BUNDLES && enableBundleDiscounts && bundleDiscountPercent) {
        const bundleDiscount = bundleDiscountPercent / 100
        finalPrice = Math.round(fullPrice - (fullPrice * bundleDiscount))
        discountAmount = Math.round(fullPrice * bundleDiscount)
    }

    const showStrikethroughPrice = fullPrice !== finalPrice

    const finalPriceClasses = classNames(styles.finalPrice)
    const strikethroughPriceClasses = classNames(styles.strikethroughPrice)
    const finalPriceClass = classNames(styles.finalPriceContainer, 'u-fullWidth')
    const containerClasses = classNames(styles.container, 'u-marginVertical--xs', className)

    const strikethroughPrice = () => {
        return (
            showStrikethroughPrice &&
            <span className={strikethroughPriceClasses} data-selector='strikethrough-price'>{formatCurrency(fullPrice)}</span>
        )
    }

    return (
        <div className={containerClasses}>
            <div className={finalPriceClass}>
                {strikethroughPrice()}
                <span key={finalPrice} className={finalPriceClasses} data-selector='final-price'>{formatCurrency(finalPrice)}</span>
                {discountAmount > 0 &&
                    <Badge className={styles.badge} text={`Save ${formatCurrency(discountAmount)}`} kind={badgeColor} position="inline" dataSelector="discount-pill"/>
                }
            </div>
        </div>
    )
}

ProductPrice.propTypes = {
    productCode: PropTypes.string,
    className: PropTypes.string,
}

export default ProductPrice
