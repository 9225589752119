import { useEffect, useRef } from 'react'
import { PRODUCT_CODES, PRODUCT_COLLECTIONS } from '@/constants'
import {
    useProductData,
    useProductState,
    useUpdateProductAttributes,
    findMatchingVariant,
    findMatchingVariants
} from '@saatva-bits/pattern-library.modules.selection'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import qs from 'qs'
import { DEFAULT_PRODUCT_CONFIG, getProductConfigValue, getUserDefinedAttributes } from '@/utils/productConfig'

// productCode is the primary product on a given PDP
// productsToAdd is an array of objects that includes the primary product and any addons
function useProductConfig (productCode, productsToAdd) {
    const hasRendered = useRef(false)
    const productState = useProductState(productCode)
    const productData = useProductData(productCode)
    const updateProductAttributes = useUpdateProductAttributes()
    const queryParams = typeof window !== 'undefined' && qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const skuParam = queryParams.sku
    const storage = storageWrapper()

    const modifyProductConfig = (productCode, currentProductConfig = {}) => {
        // Set default values of null but override with existing product config values if they exist
        const currentSelections = {
            ...DEFAULT_PRODUCT_CONFIG,
            ...(currentProductConfig)
        }

        const pdpsExcludedFromProductConfigUpdates = [
            ...PRODUCT_COLLECTIONS.PILLOWS, // all pillows are excluded
            PRODUCT_CODES.SCENTED_VOTIVES,
            PRODUCT_CODES.SCENTED_CANDLES,
            PRODUCT_CODES.WEIGHTED_BLANKET,
            PRODUCT_CODES.SILK_EYE_MASK,
            PRODUCT_CODES.WEIGHTED_SILK_EYE_MASK
        ]

        if (!pdpsExcludedFromProductConfigUpdates.includes(productCode)) {
            productsToAdd.forEach((product) => {
                // If the primary product on the PDP
                if (product.parentSku === productCode) {
                    const attributeValues = getProductConfigValue(product, productState)
                    attributeValues.forEach((attribute) => {
                        if (attribute.code && attribute.value) {
                            currentSelections[attribute.code] = attribute.value
                        }
                    })
                }
            })
        }

        storage.setItem('productConfig', currentSelections, 'local')
    }

    function getInStockAttributeOverrides(currentProductConfig) {
        const userDefinedAttributes = getUserDefinedAttributes(productCode, productData.attributeSets, currentProductConfig)

        // determine if desired product is in stock
        const desiredProduct = findMatchingVariant(
            productData.variants,
            {...productState, ...userDefinedAttributes},
            productData.configurableAttributes
        )

        if (!desiredProduct || desiredProduct?.inStock) {
            // We couldn't deduce the desired product, or we did and it's in stock,
            // return the user defined attributes to update the selection (standard behavior)
            return userDefinedAttributes
        } else {
            // Find any other variants (ie: colors) matching the desired attribute(s) that are in stock
            const candidateVariants = findMatchingVariants(
                productData.variants,
                userDefinedAttributes,
                Object.keys(userDefinedAttributes)
            )
            const targetProduct = candidateVariants?.find((product) => product.inStock)

            // If target product is in stock, return the relevant attributes to select this product.
            // If no in stock target product is found, this returns null to indicate no selection update is required
            return targetProduct?.attributes.reduce((acc, attribute) => ({
                ...acc,
                [attribute.code]: attribute.value
            }), {})
        }
    }

    // Runs on every render to set the productConfig value.
    // Unless there's a SKU value, in which case we need to update the productConfig because SKU takes priority.
    useEffect(() => {
        const currentProductConfig = storage.getItem('productConfig', 'local')
        const setConfig = () => {
            modifyProductConfig(productCode, currentProductConfig)
        }

        if (!hasRendered.current) {
            if (!skuParam) {
                // updates the state on first render using selection hook

                // If we can't find a product with the user-preferred attributes that is in stock,
                // don't update the selection (defer to the default product from the content service)
                const attributesToUpdate = getInStockAttributeOverrides(currentProductConfig)
                attributesToUpdate && updateProductAttributes(productCode, attributesToUpdate)
            }
            // Updates the state of the product based on productConfig values during the first render
            // Adding a one second delay to prevent race condition where Safari flickers and overwrites with the default size
            setTimeout(() => hasRendered.current = true, 1000)
        } else {
            setConfig() // state update is handled by selection context in subsequent renders, so only update the productConfig
        }
    })
}

export default useProductConfig
