// import React from 'react'
import PropTypes from 'prop-types'
import { useProductState, useVariantsData } from '@saatva-bits/pattern-library.modules.selection'
import styles from './WhatsIncludedBB.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { capitalize } from 'lodash'
import { FOUNDATION, ORGANIC_COTTON_SHEETS } from '@/constants/product-codes'

const WhatsIncludedBB = ({
    productCode,
    title
}) => {
    const { bundledVariants } = useProductState(productCode)
    const variantsData = useVariantsData(bundledVariants || [])

    if(!variantsData.length) {
        return null
    }

    const titleSction = title || `What's included:`
    
    const productName = (variant, index) => {
        if (!variant) return ''

        // This order is mainly focus on a mattress attributes. The main one being size.
        const attributes = [
            index === 0 ? variant.size : null,
            variant.foundationHeight && `${variant.foundationHeight}"`,
            variant.productCode === ORGANIC_COTTON_SHEETS ? variant.color : null,
            variant.comfortLevel,
            variant.mattressHeight && `${variant.mattressHeight}"`,
            variant.pillowHeightOption,
        ].filter(Boolean).join(', ')
        // Modify this once this EX-446 is done. Check Foundation validation is not required anymore
        const itemName = variant.productCode === FOUNDATION ? capitalize(variant.productCode) : variant.genericName || variant.name
        const quantity = bundledVariants.find(product => product.productCode === variant.productCode)?.quantity || ''
    
        return `${quantity} ${itemName}${quantity >= 2? 's' : ''}${attributes ? ` (${attributes})` : ''}`.trim()
    }

    const bundleItems = variantsData.map((variant, index) => {
        return(
            <li className={`u-marginBottom--sm ${styles.bundleItems}`} key={`whats-included-${variant.productCode}`}>
                <span className={styles.bundleItemsName}>{productName(variant, index)}</span>
                <span><SvgSprite spriteID='icon-checkmark-no-circle' className={styles.icon}/></span>
            </li>
        )
    })

    return(
        <section id={`${styles.bundleItemsSection}`} className={`${styles.bundleItemsSection}`}>
            <h3 className={`u-textLeft ${styles.title}`}>{titleSction}</h3>
            <ul className={styles.bundleList}>    
                {bundleItems}
            </ul>
        </section>
    )
}

WhatsIncludedBB.propTypes = {
    productCode: PropTypes.string,
    title: PropTypes.string
}

export default WhatsIncludedBB
