/*
    This component wraps the CrossSells component to prevent needing to access product
    state from within the buystack component, which cuases the entire buystack to re-render
    when the product state changes.
*/

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Global } from '@/contexts'
import CrossSells from '@/components/CrossSells'
import { useProductData, useProductState } from '@saatva-bits/pattern-library.modules.selection'
import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'
import { BED_FRAMES, PLATFORM_BASES } from '@/constants/subcategories'

const CrossSellsBuystackWrapper = ({
    crossSellData,
    title,
    classes,
    isCompact
}) => {
    const { urlProductCode: productCode } = useContext(Global.Context)

    const { subcategory } = useProductData(productCode)
    const primaryProductState = useProductState(productCode)
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)
    const inStock = bundledVariants.length ? bundledVariants.every((variant => variant.inStock)) : primaryProductState.inStock
    const crossSellSubcategories = [BED_FRAMES, PLATFORM_BASES]
    const showCrossSells = crossSellSubcategories.includes(subcategory) && !inStock
    // if the product is a bed frame or platform base and the product is out of stock, show the cross sell
    if (!showCrossSells) return null

    return (
        <CrossSells
            crossSellData={crossSellData}
            title={title}
            classes={classes}
            isCompact={isCompact}
        />
    )
}

CrossSellsBuystackWrapper.propTypes = {
    crossSellData: PropTypes.string,
    title: PropTypes.string,
    classes: PropTypes.string,
    isCompact: PropTypes.bool
}

export default CrossSellsBuystackWrapper
