import { CLASSIC_STARTER_BUNDLE, FOUNDATION, ADJUSTABLE_FRAME, SAATVA_CLASSIC, ORGANIC_MATTRESS_PAD,
    SATEEN_SHEETS_LATEX_PILLOW, ORGANIC_COTTON_SHEETS, THE_SAATVA_PILLOW,
    SATEEN_SHEETS_MEMORY_FOAM_PILLOW, MEMORY_FOAM_PILLOW, 
    SATEEN_SHEETS_LATEX_PILLOW_PAD} from '@/constants/product-codes'

// Currently supported `displayData` overrides include:
//      name
//      description
//      url
//      imageName
//      imageFolder
//      attributeLabels

// `displayData` should have identical structure for merge products as it does
// for the individal products (the latter accessed via a product code key).

// `attributeLabels` is an object allowing you to override the displayName
// for product options using the following structure:
//      `{ <attributeCode>: <desiredLabelString> }`

const config = {
    [CLASSIC_STARTER_BUNDLE]: {
        displayData: {
            [SAATVA_CLASSIC]: {
                name: <span>Saatva Classic Mattress</span>,
                description: <ul>
                    <li>America's best-selling online luxury innerspring</li>
                    <li>3" Euro pillow top</li>
                    <li>Durable & responsive dual-coil design</li>
                </ul>,
                attributeLabels: {
                    'mattressHeight': 'Height'
                }
            },
            [FOUNDATION]: {
                name: 'Foundation',
                description: <ul>
                    <li>More durable than a traditional box spring</li>
                    <li>Adds optimal support and durability</li>
                </ul>
            },
            [ADJUSTABLE_FRAME]: {
                name: 'Universal Frame',
                isProductLinkHidden: true,
                description: <ul>
                    <li>Steel frame adjusts to fit any size foundation/box spring</li>
                    <li>Supports and lifts your foundation and mattress 7.5” off the ground</li>
                </ul>
            },
            [ORGANIC_MATTRESS_PAD]: {
                description: <ul>
                    <li>Plush GOTS®-certified organic cotton</li>
                    <li>Breathable comfort and protection from light stains and general wear & tear</li>
                </ul>

            }
        }
    },
    [SATEEN_SHEETS_LATEX_PILLOW]: {
        displayData: {
            [THE_SAATVA_PILLOW]: {
                name: <span>Saatva Latex Pillow</span>,
                description: <ul>
                    <li>Shredded natural latex core for responsive head and neck support</li>
                    <li>Full size and larger includes two pillows</li>
                </ul>
            },
            [ORGANIC_COTTON_SHEETS]: {
                name: <span>Sateen Sheet Set</span>,
                description: <ul>
                    <li>Silky-soft 300 thread count organic cotton</li>
                    <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcase</li>
                </ul>
            }
        }
    },
    [SATEEN_SHEETS_LATEX_PILLOW_PAD]: {
        displayData: {
            [THE_SAATVA_PILLOW]: {
                name: <span>Saatva Latex Pillow</span>,
                description: <ul>
                    <li>Shredded natural latex core for responsive head and neck support</li>
                    <li>Full size and larger includes two pillows</li>
                </ul>
            },
            [ORGANIC_MATTRESS_PAD]: {
                name: <span>Organic Mattress Pad</span>,
                description: <ul>
                    <li>Plush GOTS®-certified organic cotton</li>
                    <li>Breathable comfort and protection from light stains and general wear & tear</li>
                </ul>
            },
            [ORGANIC_COTTON_SHEETS]: {
                name: <span>Sateen Sheet Set</span>,
                description: <ul>
                    <li>Silky-soft 300 thread count organic cotton</li>
                    <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcase</li>
                </ul>
            }
        }
    },
    [SATEEN_SHEETS_MEMORY_FOAM_PILLOW]: {
        displayData: {
            [MEMORY_FOAM_PILLOW]: {
                name: <span>Graphite Memory Foam Pillow</span>,
                description: <ul>
                    <li>Advanced graphite technology draws excess heat away</li>
                    <li>Full size and larger includes two pillows</li>
                </ul>
            },
            [ORGANIC_COTTON_SHEETS]: {
                name: <span>Sateen Sheet Set</span>,
                description: <ul>
                    <li>Silky-soft 300 thread count organic cotton</li>
                    <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcase</li>
                </ul>
            }
        }
    }
}

export default config
