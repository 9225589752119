import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'

import LegacyPicture from '@/components/LegacyPicture'
import useAddon from '@/hooks/addon'
import { PRODUCT_CODES } from '@/constants'

import { Global } from '@/contexts'
import AddOnToggle from '../AddonToggle/AddonToggle'
import PriceSnapShot from '../PriceSnapShot/PriceSnapShot'

const {
    ADJUSTABLE_FRAME: FRAME_CODE,
    FOUNDATION,
    FOUNDATION_HD,
    ADJUSTABLE_BASE: ADJ_BASE_CODE,
    ADJUSTABLE_BASE_PLUS: ADJ_BASE_PLUS_CODE
} = PRODUCT_CODES

const FoundationBaseInfoModal = ({
    addonLabel,
    foundationPrice,
    foundationValueDiscountAmount,
    framePrice,
    sabpPrice,
    sabpValueDiscountAmount,
    setIsModalVisible,
    mattressSize
}) => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const isHD = productCode === PRODUCT_CODES.SAATVA_HD
    const FOUNDATION_CODE = isHD ? FOUNDATION_HD : FOUNDATION
    const { name: sabpName } = useProductData(ADJ_BASE_PLUS_CODE)
    const { isAddonSelected: isFrameSelected, updateAddons } = useAddon(FRAME_CODE, addonLabel)
    const { isAddonSelected: isFoundationSelected } = useAddon(FOUNDATION_CODE, addonLabel)
    const { isAddonSelected: isBasePlusSelected } = useAddon(ADJ_BASE_PLUS_CODE, addonLabel)

    const toggleFoundationAndFrame = (on) => {
        if (on) {
            updateAddons({
                addCodes: [FOUNDATION_CODE, FRAME_CODE],
                removeCodes: [ADJ_BASE_CODE, ADJ_BASE_PLUS_CODE]
            })
        } else {
            updateAddons({
                removeCodes: [FOUNDATION_CODE, FRAME_CODE]
            })
        }
    }

    const handleFoundationAndFrameChange = () => {
        if (isFoundationSelected && isFrameSelected) {
            toggleFoundationAndFrame(false)
        } else {
            toggleFoundationAndFrame(true)
        }
    }

    const toggleBasePlus = (on) => {
        if (on) {
            updateAddons({
                addCodes: [ADJ_BASE_PLUS_CODE],
                removeCodes: [ADJ_BASE_CODE, FOUNDATION_CODE, FRAME_CODE]
            })
        } else {
            updateAddons({
                removeCodes: [ADJ_BASE_PLUS_CODE]
            })
        }
    }

    const handleBasePlusChange = () => {
        if (isBasePlusSelected) {
            toggleBasePlus(false)
        } else {
            toggleBasePlus(true)
        }
    }

    const modalClasses = classNames('foundationBaseInfoModal foundationBaseInfoModal__addOn')
    const introContainerClasses = classNames('foundationBaseInfoModal__introContainer foundationBaseInfoModal__introContainer--rightFoundation u-flexDisplay u-flexAlign--center')
    const introClasses = classNames('foundationBaseInfoModal__intro foundationBaseInfoModal__intro--rightFoundation')
    const priceContainerClasses = classNames('foundationBaseInfoModal__addOnPrice foundationBaseInfoModal__addOnPrice--rightFoundation')
    const priceClasses = classNames('foundationBaseInfoModal__prices')
    const toggleContainerClasses = ('foundationBaseInfoModal__addOnToggle')
    const toggleClasses = classNames('foundationBaseInfoModal__toggle')
    const pictureClasses = classNames('foundationBaseInfoModal__image foundationBaseInfoModal__image--rightFoundation')

    const foundationAndFrameToggleAndPrice = () => {
        const products = [
            {
                productCode: FOUNDATION_CODE,
                price: foundationPrice,
                discountAmount: foundationValueDiscountAmount,
                shortName: 'Foundation',
                size: mattressSize
            },
            {
                productCode: FRAME_CODE,
                price: framePrice,
                shortName: 'Frame'
            }
        ]

        return (
            <div className={modalClasses}>
                <div className={toggleContainerClasses}>
                    <AddOnToggle
                        className={toggleClasses}
                        isSelected={isFoundationSelected && isFrameSelected}
                        onClick={() => handleFoundationAndFrameChange()}
                        addOnText={`Add ${isHD ? 'HD ' : ''}Foundation + Frame`}
                    />
                </div>
                <div className={priceContainerClasses}>
                    <PriceSnapShot className={priceClasses} products={products} />
                </div>
            </div>
        )
    }

    const sabpToggleAndPrice = () => {
        const products = [
            {
                productCode: ADJ_BASE_PLUS_CODE,
                price: sabpPrice,
                discountAmount: sabpValueDiscountAmount,
                size: mattressSize
            }
        ]

        return (
            <div className={modalClasses}>
                <div className={toggleContainerClasses}>
                    <AddOnToggle className={toggleClasses} isSelected={isBasePlusSelected} onClick={() => handleBasePlusChange()} addOnText='Add Adj. Base Plus' />
                </div>
                <div className={priceContainerClasses}>
                    <PriceSnapShot className={priceClasses} products={products} />
                </div>
            </div>
        )
    }

    const foundationListElements = isHD ? [
        <li key="foundation-list-1">Saatva HD is designed to pair with an HD foundation and NOT compatible with regular foundations and box springs</li>,
        <li key="foundation-list-2">Available in 4.75" or 8.75" heights</li>
    ] : [
        <li key="foundation-list-1">Provides more durable and stable support than a box spring</li>,
        <li key="foundation-list-2">Made with  eco-friendly foams</li>,
        <li key="foundation-list-3">Available in 4.75" or 8.75" heights</li>
    ]

    return (
        <section className="u-textCenter">
            <p className="u-marginBottom--2dot5xl">Get the most out of your mattress by adding a foundation or adjustable base.</p>
            <div className="foundationBaseInfoModal__section">
                <div className={introContainerClasses}>
                    <div className="foundationBaseInfoModal__imageContainer">
                        <LegacyPicture
                            className={pictureClasses}
                            folder="bases"
                            name="outline-foundation-frame"
                            alt="Foundation and Frame outline"
                            type="svg"
                            hasWebp={false}
                            lazyLoad={false}
                            targetedDevices={[]} />
                    </div>
                    <div className={introClasses}>
                        <h2 className="t-heading4">{isHD ? 'HD ' : ''}Foundation &amp; Frame</h2>
                        <ul className="theRightFoundation__list">{foundationListElements}</ul>
                    </div>
                    {foundationAndFrameToggleAndPrice()}
                </div>
            </div>

            <div className="foundationBaseInfoModal__section">
                <div className={introContainerClasses}>
                    <div className="foundationBaseInfoModal__imageContainer">
                        <LegacyPicture
                            className={pictureClasses}
                            folder="bases"
                            name="outline-adjustableBasePlus"
                            alt="Adjustable Base outline"
                            type="svg"
                            hasWebp={false}
                            lazyLoad={false}
                            targetedDevices={[]} />
                    </div>
                    <div className={introClasses}>
                        <h2 className="t-heading4">{sabpName}</h2>
                        <ul className="theRightFoundation__list">
                            <li>Elevate head and feet for weightless support</li>
                            <li>Wireless remote or optional app control</li>
                            <li>One-touch remote</li>
                            <li>Leg, head, and full body wave massage feature</li>
                            <li>Underbed lighting</li>
                            <li>Whisper-soft motor</li>
                            <li>Compatible with most bed frames including slatted platform beds.</li>
                            <li>USB-A & USB-C ports on both sides</li>
                        </ul>
                    </div>
                    {sabpToggleAndPrice()}
                </div>
            </div>
            <p className="u-marginTop--2xl">If you need help choosing the right bed base, <button className="t-link" onClick={() => {
                OpenChat()
                setIsModalVisible(false)
            }}>chat</button> with us or call <a className="t-link" href="tel:+18776722882">877-672-2882</a>.</p>
        </section>
    )
}

FoundationBaseInfoModal.propTypes = {
    addonLabel: PropTypes.string,
    foundationPrice: PropTypes.number,
    foundationValueDiscountAmount: PropTypes.number,
    framePrice: PropTypes.number,
    sabpPrice: PropTypes.number,
    sabpValueDiscountAmount: PropTypes.number,
    mattressSize: PropTypes.string
}

export default FoundationBaseInfoModal
