import React, { useState, useRef, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Global } from '@/contexts'
import { BidirectionalTab, BidirectionalTabs } from '@/components/BidirectionalTabs'
import { useProductState, useUpdateProductAttributes } from '@saatva-bits/pattern-library.modules.selection'

import tabsConfig from './ScaleComfortLevelConfig'

import styles from './ScaleComfortLevels.module.scss'

import BuystackAdditionalTooling from '../BuystackAdditionalTooling'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import useLearnMoreModal from '@/hooks/useLearnMoreModal'

/*
 * This component is a subtle variation of the TabbedContentConfig,
 * adding support for state to allow for updating the selection context.
 * These components are candidates to be refactored to share code.
 */

/*
 * This component shares its config content with the `TabbedContentDisplay` "sister-component".
 * Be sure any changes to the structure of the config doesn't break the other!
*/

const ScaleComfortLevels = ({
    updateProductState = true,
    defaultActiveIndex = 0
}) => {
    const tabScrollRef = useRef(null)
    const { urlProductCode: productCode } = useContext(Global.Context)
    const updateProductAttributes = useUpdateProductAttributes()
    const productState = useProductState(productCode)
    const selectedComfort = productState.comfortLevel
    const updateSelectionOnChange = updateProductState && productState.attributes.find(att => att.code === 'comfortLevel')

    const { setIsModalOpen } = useLearnMoreModal()

    const {
        hasAlternateColor = false,
        tabs
    } = tabsConfig[productCode]

    const getSelectedComfortLevel = tabs.findIndex(tab => tab.tabName === selectedComfort)
    const [activeIndex, setActiveIndex] = useState(getSelectedComfortLevel >= 0 ? getSelectedComfortLevel : defaultActiveIndex)

    const tabContent = tabs.map((tab, i) => {
        const tabContentClasses = classNames(styles.tabContentWrapper, {
            [styles['is-active']]: activeIndex === i
        }, tab.customClass)

        return (
            <div
                role="tabpanel"
                id={`scaleComfortLevelPanel-${i}`}
                aria-labelledby={`scaleComfortLevelTab-${i}`}
                className={tabContentClasses}
                key={`tabContent-${i}`}
                tabIndex={0}>
                <div className={''}>
                    <h2 className={styles.tabHeading}>{tab.heading}</h2>
                    <div className={styles.tabContent}>
                        {tab.content}
                    </div>
                </div>
            </div>
        )
    })

    // Once it is open, <BuystackAdditionalTooling> appears in the <Buystack>
    useEffect(() => {
        setIsModalOpen(true)
    }, [])

    const handleTabClick = (ariaControl = '') => {
        // ariaControl argument is coming from the BidirectionalTab.jsx component and is equal to selectedAria
        const currentIndex = Number(ariaControl.substring(ariaControl.length - 1))
        setActiveIndex(currentIndex)
    }

    const onComfortLevelSelected = (tabSelected) => {
        if (updateSelectionOnChange){
            updateProductAttributes(productCode, { comfortLevel: tabSelected })
        }
    }

    const renderTabs = () => {
        return tabs.length > 1 && (
            <BidirectionalTabs
                onChange={handleTabClick}
                selectedAria={`scaleComfortLevelPanel-${activeIndex}`}
                sliderBar={false}
                className={styles.tabList}>
                {tabs.map((tab, index) => {
                    const bidirectionalTabClasses = classNames(styles.tabName, {
                        [styles['tabName--isActive']]: activeIndex === index,
                        [styles['tabName--blueColor']]: hasAlternateColor,
                        [styles['tabName--blueColor--isActive']]: (activeIndex === index && hasAlternateColor)
                    })
                    return (
                        <BidirectionalTab
                            onChange={onComfortLevelSelected}
                            ariaControls={`scaleComfortLevelPanel-${index}`}
                            label={tab.tabName}
                            id={`scaleComfortLevelTab-${index}`}
                            className={bidirectionalTabClasses}
                            key={`scaleComfortLevelTab-${index}${activeIndex}`}>
                            {tab.tabName}
                        </BidirectionalTab>
                    )
                })}
            </BidirectionalTabs>
        )
    }

    // This is for the test EXP.COMFORT_QUIZ.EX-220
    // This removes all the content in ScaleComfortLevels
    // And renders just the video
    const [showVideo, setShowVideo] = useState(false)

    const onTriggerVideo = () => {
        setShowVideo(true)
    }

    const deleteElement = () => {
        const element = document.querySelector(`[class*="modal__header"]`)
        if (element) {
            element.remove()
        }
    }

    if(showVideo) {
        deleteElement()
    }
    // End Test EXP.COMFORT_QUIZ.EX-220

    return (
        <React.Fragment>
            {!showVideo && <div className={styles.scaleComfortLevel}>
                <div className={styles.tabListScroll} ref={tabScrollRef}>
                    { renderTabs() }
                </div> 
                {tabContent}
            </div>}
            {productCode === SAATVA_CLASSIC && <BuystackAdditionalTooling dataSelectorTakeQuiz='comfort-quiz-cta-modal' dataSelectorWatchVideo='modal-open-comfort-video' productCode={SAATVA_CLASSIC} showAlways onlyShowVideo={showVideo} backgroundColor={'white'} onTriggerVideo={onTriggerVideo}/>}
        </React.Fragment>

    )
}

ScaleComfortLevels.propTypes = {
    // Optional override to ignore selection context (ie: when placed outside the buystack)
    updateProductState: PropTypes.bool,
    defaultActiveIndex: PropTypes.number
}

export default ScaleComfortLevels
