import React from 'react'
import qs from 'qs'

import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'
import { buildPreloadURL, PDP_CAROUSEL_HERO_WIDTHS } from '@saatva-bits/pattern-library.utils.product'
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { BREAKPOINTS } from '@saatva-bits/pattern-library.utils.position'
import { getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

import { SAATVA_CLASSIC } from '@/constants/product-codes'
import { findSelectedVariant } from '@/utils/products'
import styles from '@/components/ProductCarouselGallery/ProductCarouselGallery.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL
const DEFAULT_THUMBNAIL_WIDTH = 100
// Handles both hero and thumbnail images
export function createAssetDrivenImage (image, alt, imgixDomain, isHero, isLazyLoaded = false) {
    const imageClassName = isHero ? 'pdpCarousel__heroImages--image' : styles.pdpCarouselThumbnailsImage
    const widths = {
        desktop: isHero ? PDP_CAROUSEL_HERO_WIDTHS['desktop'] : DEFAULT_THUMBNAIL_WIDTH // TODO: move away from magic number
    }

    if (!image) return null
    const imgixOptions = {
        default: {
            auto: 'format,compress'
        }
    }

    return (
        <Picture
            key={`${image.filename}`}
            imageClassName={imageClassName}
            className={isHero && styles.pdpCarouselAssetsPicture}
            widths={widths}
            prefixOverride={{
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            }}
            folder={image.folder}
            name={image.filename}
            alt={alt}
            imgixDomain={imgixDomain || IMGIX_URL}
            lazyLoad={isLazyLoaded}
            imgixOptions={imgixOptions}
        />
    )
}

// Helper function for creating a video thumbnail. This is needed because the PictureImgix component won't work
// with video parameters and VideoImgix expects a video rather than an image.
export function createImgixVideoThumbnail (
    videoOptions,
    handleOnClick,
    imageClassNames,
    includePlayIcon = true,
    shouldReturnJustImage = false,
    aspectRatio = '1-1',
) {
    const { imgixSubdomain } = videoOptions // fallback in case not set in the config
    const { folder, filename, extension, thumbnailAltTag } = videoOptions
    const posterOptions = {
        'video-generate': 'thumbnail',
        'video-thumbnail-time': -1, // first frame of the video
        'w': DEFAULT_THUMBNAIL_WIDTH
    }

    const thumbnailFilename = `${filename}-${aspectRatio}.${extension}`
    const src = `https://${imgixSubdomain}/${folder}/${filename}/${thumbnailFilename}?${qs.stringify(posterOptions)}`

    const renderImage = <>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={src} alt={thumbnailAltTag} className={imageClassNames} />
        {/* Allows us to hide the play icon in the zoom gallery thumbnail. */}
        {includePlayIcon && <SvgSprite
            spriteID='icon-function-fill-play'
            className={styles.pdpCarouselThumbnailVideoOverlay}
        />}
    </>
    if (shouldReturnJustImage) {
        return renderImage
    }

    // Must use an img tag because the PictureImgix component won't work with video parameters
    return (
        <button
            className={styles.pdpCarouselThumbnailVideo}
            data-selector='product-carousel-slider-video-thumbnail'
            onClick={handleOnClick}
        >
            {renderImage}
        </button>
    )
}

export function createImgixVideo (videoOptions, index, classes) {
    return (
        <VideoImgix key={`video${index}`} className={classes} {...videoOptions} />
    )
}

// Used to add preload tags to the server rendered HTML
export const createAssetsToPreload = (productCode, product, productOverrides, useAlternateAspectRatio = false) => {
    const carouselDescriptors = product?.content?.ardadDescriptors?.find((descriptor) => descriptor.slot === 'carousel')?.descriptors || []
    
    const selectedVariant = findSelectedVariant(product, productOverrides)

    const isCarouselRedesignPage = productCode === SAATVA_CLASSIC && useAlternateAspectRatio
    const deviceMap = {
        'mobile': {
            aspectRatio: isCarouselRedesignPage ? '3-2' : '16-9',
            maxWidth: BREAKPOINTS.md - 1,
        },
        'tablet': {
            aspectRatio: isCarouselRedesignPage ? '3-2' : '16-9',
            minWidth: BREAKPOINTS.md,
            maxWidth: BREAKPOINTS.lg - 1,
        },
        'desktop': {
            aspectRatio: isCarouselRedesignPage ? '4-3' : '3-2',
            minWidth: BREAKPOINTS.lg,
        }
    }

    let assetsToPreload = []
    const devices = Object.keys(deviceMap)
    devices.forEach((device) => { // Needs to return two entries per device (1x and 2x) so we use forEach instead of map
        const { aspectRatio, minWidth, maxWidth } = deviceMap[device]
        let productImage = {}
        try {
            productImage = getAssetForVariant(selectedVariant, carouselDescriptors[0], aspectRatio) || {}    
        } catch (error) {
            logger.debug({
                message: `Could not get assetForVariant for product ${productCode} using variant ${selectedVariant.sku}`,
                location: 'pdpCarousel.createAssetsToPreload',
                details: {
                    productCode: productCode,
                    sku: selectedVariant.sku,
                    descriptor: carouselDescriptors[0],
                    aspectRatio
                },
                error
            })
        }
        
        const { folder, file: filename } = productImage

        if (!folder || !filename) {
            logger.debug({
                message: `No image found for product ${productCode} using variant ${selectedVariant.sku}`,
                location: 'pdpCarousel.createAssetsToPreload',
                details: {
                    productCode: productCode,
                    sku: selectedVariant.sku
                }
            })
            return
        }
        const width = PDP_CAROUSEL_HERO_WIDTHS[device]
        const imgixOptions = { auto: 'format,compress', w: width }
        const imagePath = `${folder}/${filename}`
        const regularHref = buildPreloadURL(IMGIX_URL, imagePath, { dpr: 1, ...imgixOptions })
        const highDpiHref = buildPreloadURL(IMGIX_URL, imagePath, { dpr: 2, ...imgixOptions })
        const minWidthString = minWidth ? `(min-width: ${minWidth}px) and ` : ''
        const maxWidthString = maxWidth ? `(max-width: ${maxWidth}px) and ` : ''

        assetsToPreload.push({
            href: regularHref,
            media: `${minWidthString}${maxWidthString}(max-resolution: 191dpi)`
        }, {
            href: highDpiHref,
            media: `${minWidthString}${maxWidthString}(min-resolution: 192dpi)`
        })
    })

    return assetsToPreload
}

export const getArdadImages = (productState, descriptors, aspectRatios, zoomGallery = false) => {
    const images = []

    descriptors.forEach(descriptor => {
        try {
            if (descriptor === 'video' && !zoomGallery) {
                // Used for saatva classic video
                // product carousel checks for video keyword and replaces it with assets from imgix.video
                // Placing this in PDP app since other apps don't display the video
                // Should not be present for zoom gallery
                images.push({
                    folder: 'video',
                    filename: 'video',
                    altTag: `Video for ${productState?.genericName}`
                })
            } else {
                const { folder, file, altTag } = getAssetForVariant(productState, descriptor, aspectRatios)
                images.push({
                    folder,
                    filename: file,
                    altTag
                })
            }
        } catch (err) {
            logger.debug({
                message: 'Cannot find ARDAD asset for variant',
                location: 'components.ProductCarouselWrapper.createAssetDrivenImages.ardadImages',
                details: {
                    productCode: productState.productCode,
                    sku: productState.sku,
                    descriptor
                },
                err
            })
        }
    })

    return images
}
