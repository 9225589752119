import { useProductState, useProductsState } from '@saatva-bits/pattern-library.modules.selection'
import { useGetDiscounts, usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useAddonContext } from '@/contexts/addon'
import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'
import { CATEGORIES } from '@/temp-configs/category-constants'

const useProductPricingDetails = (productCode, includeAddons = true) => {
    const { getDiscounts } = useGetDiscounts()
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent } = sitewidePromo

    // get the states of the selected addons
    const { selectedAddonProductCodes, getProductCode } = useAddonContext()
    const addonProductCodes = includeAddons
        ? [...selectedAddonProductCodes].map((code) => getProductCode(code))
        : []
    const addonVariants = useProductsState(addonProductCodes)

    // get the state of the primary product
    const primaryProductState = useProductState(productCode)

    // if the primary product is a bundle, get the data for each product variant included in the bundle
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)

    // if the primary product is a bundle, use the bundled variants to calculate the discounts, otherwise use the primary product
    // include the addon variants in the discount calculation only if includeAddons is true
    let variantsForDiscounts = bundledVariants.length
        ? [...bundledVariants, ...(includeAddons ? addonVariants : [])]
        : [primaryProductState, ...(includeAddons ? addonVariants : [])]

    const productInfoForDiscount = variantsForDiscounts.map(variant => {
        return {
            category: variant.category.toLowerCase(),
            isInStock: variant.inStock,
            isPrimaryProduct: variant.isPrimaryProduct || true,
            parentSku: variant.productCode,
            price: variant.price,
            quantity: variant.quantity || 1,
            sku: variant.sku
        }
    })

    let {
        fullPrice,
        finalPrice,
        discountAmount,
        labelBreakdown
    } = getDiscounts(productInfoForDiscount)

    // temporarily handling bundle discounts.
    if (primaryProductState.category.toLowerCase() === CATEGORIES.BUNDLES && enableBundleDiscounts && bundleDiscountPercent) {
        const bundleDiscount = bundleDiscountPercent / 100
        finalPrice = Math.round(fullPrice - (fullPrice * bundleDiscount))
        discountAmount = Math.round(fullPrice * bundleDiscount)
    }

    // determine if the primary product or bundled products are in stock
    // do not check if the addon products are in stock, if they are out of stock, they won't be added to cart
    const inStock = bundledVariants.length ? bundledVariants.every((variant => variant.inStock)) : primaryProductState.inStock

    return {
        fullPrice,
        finalPrice,
        discountAmount,
        labelBreakdown,
        sitewidePromo,
        inStock
    }
}

export default useProductPricingDetails
