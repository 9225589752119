import React from 'react'
import { QuantitySelector, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import AddToCart from '../AddToCart/AddToCart'
import styles from './QuantityAndAddToCart.module.scss'
import PropTypes from 'prop-types'

const QuantityAndAddToCart = ({ productCode }) => {
    const { active } = useProductData(productCode)

    let content = null
    if (active) {
        content = (
            <div className={`${styles.quantityAddToCartRow} u-marginBottom u-marginTop`}>
                <QuantitySelector
                    productCode={productCode}
                    dataSelector='primaryQty'
                    className='u-marginRight'
                    displayIfOutOfStock={false}
                />
                <AddToCart productCode={productCode} />
            </div>
        )
    }

    return content
}

QuantityAndAddToCart.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    productCode: PropTypes.string
}

export default QuantityAndAddToCart
