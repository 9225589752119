import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { Loader } from '@saatva-bits/pattern-library.components.loader'
import { useProductState, useProductsState, getVariantDataForCart } from '@saatva-bits/pattern-library.modules.selection'
import { useSharedCartService } from '@saatva-bits/pattern-library.modules.cart'

import { useAddonContext } from '@/contexts/addon'
import OutOfStockMessage from '@/components/OutOfStockMessage'
import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'
import useProductConfig from '@/hooks/useProductConfig'
import useInView from '@/hooks/useInView'
import useProductPricingDetails from '@/hooks/useProductPricingDetails'

import { pushSearchProductAddToCart, pushTrendingProductAddToCart } from '@/utils/analytics'

import styles from './AddToCart.module.scss'

const AddToCart = ({
    className,
    disabled,
    productCode
}) => {
    const { addItemToCart } = useSharedCartService()
    const { query } = useRouter()

    // get the states of the selected addon products
    const { selectedAddonProductCodes, getProductCode } = useAddonContext()
    const addonProductCodes = [...selectedAddonProductCodes].map((code) => getProductCode(code))
    const addonVariants = useProductsState(addonProductCodes)

    // get the state of the primary product
    const primaryProductState = useProductState(productCode)

    // if the primary product is a bundle, get the data for each product variant included in the bundle
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)

    // parentSku is necessary for productConfig to know if it can update
    const primaryProductForProductConfig = { ...primaryProductState, parentSku: productCode}
    const primaryAndAddonProducts = [primaryProductForProductConfig, ...addonVariants]
    const bundledAndAddonProducts = [...bundledVariants, ...addonVariants]

    // if the primary product is a bundle, add bundled variants to the cart, otherwise use the primary product
    const selectedVariantsData = bundledVariants.length ? bundledAndAddonProducts : primaryAndAddonProducts
    const { items: productsToAdd, quantityOfProducts } = getVariantDataForCart(selectedVariantsData)

    // Update the productConfig cookie on every render
    // Don't include bundled products in product config, instead use the primary product bundle
    useProductConfig(productCode, primaryAndAddonProducts)

    // determine if the primary product or bundled products are in stock
    // do not check if the the addon products are in stock, if they are out of stock, they won't be added to cart
    const inStock = bundledVariants.length ? bundledVariants.every((variant => variant.inStock)) : primaryProductState.inStock

    const [isLoading, setIsLoading] = useState(false)

    const onAddToCart = async (productsToAddToCart, selectedVariants) => {
        setIsLoading(true)

        const productItems = productsToAddToCart.map((item, index) => {
            // This is for bundle products like SplitKing sizes that is composed of two mattresses
            const bundleOptions = item.bundleProductOptions
                ? Array.isArray(item.bundleProductOptions)
                    ? item.bundleProductOptions[0]
                    : item.bundleProductOptions
                : null
            return {
                sku: item.sku,
                category: item.category,
                subcategory: selectedVariants[index].subcategory,
                name: item.label,
                price: item.price,
                attributes: selectedVariants[index].attributes,
                quantity: item.quantity,
                bundleOptions: bundleOptions,
                genericName: selectedVariants[index].genericName
            }
        })

        try {
            // pass the primaryProductState, which is the currently selected bundle variant
            // this adds a special bundle item to the add to cart event
            const cart = await addItemToCart(productItems, null, 'pdp', bundledVariants.length ? primaryProductState : null)
            // Check if the searchTerm query parameter is present and push Algolia event
            const searchTerm = query.searchTerm
            if (searchTerm) {
                pushSearchProductAddToCart(cart, productItems)
            }

            const algoliaRec = query.algoliaRec
            if (algoliaRec) {
                pushTrendingProductAddToCart(cart, productItems)
            }
        } catch (err) {
            console.log('Error adding item to cart. Message: ', err)
        }
        setIsLoading(false)
    }
 
    const totalQuantity = quantityOfProducts
    const itemsToCart = totalQuantity > 1 ? 'Items to Cart' : 'Item to Cart'
    const buttonText = `Add ${itemsToCart}`

    const buttonClassName = classNames(styles.dataStreamAddToCart, className)
    const isOutOfStock = !(productsToAdd.length && inStock)

    // push analytics event when discount labels would be visible, and the ATC button is visible
    // This should occur whether we're in the v1 AND v0 experience for EXP.PROMOTIONS_MSGING.EX-450
    // This code can be removed after the we're finished with this test
    const { labelBreakdown } = useProductPricingDetails(productCode)
    let hasDiscountAmount = (Array.isArray(labelBreakdown) && labelBreakdown.length > 0) ? true : false
    const [ref, isVisible] = useInView()
    const [hasFiredEvent, setHasFiredEvent] = useState(false)

    useEffect(() => {
        if (isVisible && hasDiscountAmount && !isOutOfStock && !hasFiredEvent) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'pdpPromoMsgVisible',
            })
            setHasFiredEvent(true)
        }
    }, [isVisible, hasDiscountAmount, isOutOfStock])

    return (<>
        {isOutOfStock ? <></> :
            <div className={styles.fullWidthWrapper} ref={ref}>
                <Button
                    kind="primary"
                    className={buttonClassName}
                    disabled={disabled}
                    onClick={() => onAddToCart(productsToAdd, selectedVariantsData)}
                    data-selector="add-to-cart-button">
                    {isLoading && <Loader />}
                    {buttonText}
                </Button>
            </div>
        }
        {/* Always render the out of stock message component to handle local storage for items back in stock */}
        <OutOfStockMessage productCode={productCode} isOutOfStock={isOutOfStock} />
    </>)
}

AddToCart.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    productCode: PropTypes.string
}

export default AddToCart
