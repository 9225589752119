/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable @next/next/no-img-element */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import LazyLoad from 'react-lazyload'
import { BREAKPOINTS } from '@saatva-bits/pattern-library.utils.position'

const ROOT_ASSET_IMAGE_PATH = `${process.env.NEXT_PUBLIC_ROOT_ASSET_PATH}/images`

const DEVICES = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop'
}

const DEVICE_FILE_SPECIFIERS = {
    UNIVERSAL: 'u-',
    MOBILE: 'm-',
    TABLET: 't-',
    DESKTOP: 'd-'
}

const LegacyPicture = ({
    fullPath,
    assetRootPath,
    lazyLoad = true,
    folder,
    name,
    alt,
    className,
    pictureClassName,
    title,
    type = 'jpg',
    hasWebp = false,
    targetedDevices = Object.values(DEVICES),
    focusable = false,
    ...other
}) => {
    const rootAssetPath = assetRootPath || ROOT_ASSET_IMAGE_PATH
    const imageClasses = classNames(className)
    const sources = fullPath ? generateSourceFullPath(fullPath, type) : generateSources(rootAssetPath, folder, name, type)

    const isDeviceAgnostic = targetedDevices.length === 0
    const normalizedTargetedDevices = targetedDevices.map(device => device.toLowerCase())
    const includeMobile = normalizedTargetedDevices.includes(DEVICES.MOBILE)
    const includeTablet = normalizedTargetedDevices.includes(DEVICES.TABLET)
    const includeDesktop = normalizedTargetedDevices.includes(DEVICES.DESKTOP)

    const mobileClassNames = classNames(imageClasses, {
        'u-hidden--md-up': includeTablet,
        'u-hidden--lg-up': !includeTablet && includeDesktop
    })
    const tabletClassNames = classNames(imageClasses, {
        'u-hidden--lg-up': includeDesktop,
        'u-hidden--md-down': includeMobile
    })
    const desktopClassNames = classNames(imageClasses, {
        'u-hidden--lg-down': includeTablet || includeMobile
    })

    const tabletMedia = includeMobile ? `(min-width: ${BREAKPOINTS.md}px)` : ''
    const desktopMedia = includeTablet
        ? `(min-width: ${BREAKPOINTS.lg}px)`
        : includeMobile
            ? `(min-width: ${BREAKPOINTS.md}px)`
            : ''

    const imageTitle = title || alt
    const pictureElement = (
        <picture className={pictureClassName} {...other}>
            { /* Webp image sources, if assets are available */}
            { hasWebp &&
                <React.Fragment>
                    { isDeviceAgnostic &&
                        <source srcSet={sources.universal.webpSrcSet} type={`image/webp`} />
                    }
                    { includeDesktop &&
                        <source media={desktopMedia} srcSet={sources.desktop.webpSrcSet} type={`image/webp`} />
                    }
                    { includeTablet &&
                        <source media={tabletMedia} srcSet={sources.tablet.webpSrcSet} type={`image/webp`} />
                    }
                    { includeMobile &&
                        <source srcSet={sources.mobile.webpSrcSet} type={`image/webp`} />
                    }
                </React.Fragment>
            }

            { /* Original image type sources */}
            { isDeviceAgnostic &&
                <source srcSet={sources.universal.srcSet} type={`image/${type}`} />
            }
            { includeDesktop &&
                <source media={desktopMedia} srcSet={sources.desktop.srcSet} type={`image/${type}`} />
            }
            { includeTablet &&
                <source media={tabletMedia} srcSet={sources.tablet.srcSet} type={`image/${type}`} />
            }
            { includeMobile &&
                <source srcSet={sources.mobile.srcSet} type={`image/${type}`} />
            }

            { /* Fallback img tags for browsers not supporting picutre/source elements */}
            { isDeviceAgnostic &&
                <img className={imageClasses}
                    src={sources.universal.imgSrc}
                    srcSet={sources.universal.srcSet}
                    alt={alt}
                    title={imageTitle}
                    tabIndex={focusable ? '0' : '-1' } />
            }
            { includeMobile &&
                <img className={mobileClassNames}
                    src={sources.mobile.imgSrc}
                    srcSet={sources.mobile.srcSet}
                    alt={alt}
                    title={imageTitle}
                    tabIndex={focusable ? '0' : '-1' }/>
            }
            { includeTablet &&
                <img className={tabletClassNames}
                    src={sources.tablet.imgSrc}
                    srcSet={sources.tablet.srcSet}
                    alt={alt}
                    title={imageTitle}
                    tabIndex={focusable ? '0' : '-1' } />
            }
            { includeDesktop &&
                <img className={desktopClassNames}
                    src={sources.desktop.imgSrc}
                    srcSet={sources.desktop.srcSet}
                    alt={alt}
                    title={imageTitle}
                    tabIndex={focusable ? '0' : '-1' } />
            }

        </picture>
    )

    if (lazyLoad) {
        return (
            <LazyLoad offset={200} once>{pictureElement}</LazyLoad>
        )
    } else {
        return pictureElement
    }
}

const generateSrcSet = (assetParentDirectory, name, extension, device, densities = ['1x', '2x']) => {
    return densities
        .map(density => {
            const src = generateSource(assetParentDirectory, name, extension, device, density)
            return `${src} ${density}`
        })
        .join(', ')
}

const generateSource = (assetParentDirectory, name, extension, device = '', density = '1x') => {
    const deviceSpecifier = device
        ? DEVICE_FILE_SPECIFIERS[device.toUpperCase()]
        : DEVICE_FILE_SPECIFIERS.UNIVERSAL

    const densitySpecifier = density && extension !== 'svg'
        ? `@${density}`
        : ''

    // TODO: Do we want to add a mechanism for suborganization of images (ie: <imageName>/<version>),
    //       or require this be either part of the image name or folder?
    return `${assetParentDirectory}/${name}/${deviceSpecifier}${name}${densitySpecifier}.${extension}`
}

const generateSources = (rootAssetPath, path, name, extension = 'jpg') => {
    const assetParentDirectory = `${rootAssetPath}/${path}`
    return {
        universal: {
            webpSrcSet: generateSrcSet(assetParentDirectory, name, 'webp'),
            srcSet: generateSrcSet(assetParentDirectory, name, extension),
            imgSrc: generateSource(assetParentDirectory, name, extension)
        },
        mobile: {
            webpSrcSet: generateSrcSet(assetParentDirectory, name, 'webp', DEVICES.MOBILE),
            srcSet: generateSrcSet(assetParentDirectory, name, extension, DEVICES.MOBILE),
            imgSrc: generateSource(assetParentDirectory, name, extension, DEVICES.MOBILE)
        },
        tablet: {
            webpSrcSet: generateSrcSet(assetParentDirectory, name, 'webp', DEVICES.TABLET),
            srcSet: generateSrcSet(assetParentDirectory, name, extension, DEVICES.TABLET),
            imgSrc: generateSource(assetParentDirectory, name, extension, DEVICES.TABLET)
        },
        desktop: {
            webpSrcSet: generateSrcSet(assetParentDirectory, name, 'webp', DEVICES.DESKTOP),
            srcSet: generateSrcSet(assetParentDirectory, name, extension, DEVICES.DESKTOP),
            imgSrc: generateSource(assetParentDirectory, name, extension, DEVICES.DESKTOP)
        }
    }
}

const generateSourceFullPath = (fullPath, fileType = 'jpg') => {
    const image = fullPath

    return {
        universal: {
            webpSrcSet: generateFullSrcSet(image, false, 'webp'),
            srcSet: generateFullSrcSet(image, false, fileType),
            imgSrc: generateFullSrcSet(image, false, fileType)
        }
    }
}

// Differs from generateSrcSet, this was originally from the picture utils in coresite-node and handles a different use case.
const generateFullSrcSet = (imgSrc, breakpoint, imgSrcExtension) => {
    if (typeof imgSrc === 'object') {
        const path = breakpoint ? `${imgSrc.path}/${breakpoint}` : imgSrc.path
        return `${path}/1x/${imgSrc.name}@1x.${imgSrcExtension} 1x, ${path}/2x/${imgSrc.name}@2x.${imgSrcExtension} 2x`
    } else {
        return imgSrcExtension !== 'svg'
            ? `${imgSrc}@1x.${imgSrcExtension} 1x, ${imgSrc}@2x.${imgSrcExtension} 2x`
            : `${imgSrc}.${imgSrcExtension}`
    }
}

LegacyPicture.propTypes = {
    assetRootPath: PropTypes.string,
    lazyLoad: PropTypes.bool,
    folder: PropTypes.string,
    name: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    pictureClassName: PropTypes.string,
    type: PropTypes.string,
    hasWebp: PropTypes.bool,
    fullPath: PropTypes.string,
    targetedDevices: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(DEVICES))
    ).isRequired,
    focusable: PropTypes.bool
}

export default LegacyPicture
