import { PRODUCT_CODES, PRODUCT_COLLECTIONS } from '@/constants'

// List of default selected values used for selecting default products.
// These object keys correspond to the keys in the productConfig data in localStorage.
const DEFAULT_PRODUCT_CONFIG = {
    mattressSize: 'Queen',
    mattressHeight: '11.5',
    comfortLevel: 'luxury firm',
    rugSize: '8\' x 10\'',
    bathSet: '3-piece Bath Towel Set',
    dogBedSize: 'Medium',
    nightstandSize: '22'
}

// Returns an object with the correct key/value pair based on the product type
const getUserDefinedAttributes = (productCode, attributeSets, productConfig = DEFAULT_PRODUCT_CONFIG) => {
    let userDefinedAttributes = {}

    switch (true) {
        case PRODUCT_COLLECTIONS.RUGS.includes(productCode):
            userDefinedAttributes = { size: productConfig?.rugSize }
            break
        case PRODUCT_COLLECTIONS.TOWELS.includes(productCode):
            userDefinedAttributes = { bathSet: productConfig?.bathSet }
            break
        case PRODUCT_CODES.DOG_BED === productCode:
            userDefinedAttributes = { size: productConfig?.dogBedSize }
            break
        case PRODUCT_COLLECTIONS.NIGHTSTANDS.includes(productCode):
            userDefinedAttributes = { size: productConfig?.nightstandSize }
            break
        default:
            userDefinedAttributes = {
                size: productConfig?.mattressSize,
                mattressHeight: productConfig?.mattressHeight,
                comfortLevel: productConfig?.comfortLevel
            }
    }

    // If a user defined attribute or or it's value doesn't exist in the product's attributeSets, remove the attribute key
    Object.keys(userDefinedAttributes).forEach(key => {
        if (!attributeSets[key] || !attributeSets[key].includes(userDefinedAttributes[key])) {
            delete userDefinedAttributes[key]
        }
    })

    return userDefinedAttributes
}

const getProductConfigValue = (product, productState) => {
    const productCode = product.parentSku
    if (product.size) {
        switch (true) {
            case productState?.subcategory?.toLowerCase() === 'rugs':
                return [
                    {
                        code: 'rugSize',
                        value: product.size
                    }
                ]
            case PRODUCT_COLLECTIONS.NIGHTSTANDS.includes(productCode):
                return [
                    {
                        code: 'nightstandSize',
                        value: product.size
                    }
                ]
            case productState?.subcategory?.toLowerCase() === 'towels':
                return [
                    {
                        code: 'bathSet',
                        value: productState.bathSet
                    }
                ]
            case productCode === PRODUCT_CODES.DOG_BED:
                return [
                    {
                        code: 'dogBedSize',
                        value: product.size
                    }
                ]
            default:
                return [
                    {
                        code: 'mattressSize',
                        value: product.size
                    },
                    {
                        code: 'mattressHeight',
                        value: product.mattressHeight
                    },
                    {
                        code: 'comfortLevel',
                        value: product.comfortLevel
                    }
                ]
        }
    } else {
        return []
    }
}

export {
    DEFAULT_PRODUCT_CONFIG,
    getUserDefinedAttributes,
    getProductConfigValue
}
