import React from 'react'
import { string } from 'prop-types'
import { DiscountLabel } from '@saatva-bits/pattern-library.modules.promotions'
import useProductPricingDetails from '@/hooks/useProductPricingDetails'
import classNames from 'classnames'
import styles from './DiscountLabels.module.scss'
import logger from '@saatva-bits/pattern-library.utils.logger'

const DiscountLabels = ({ productCode, showDiscountAmount = false, includeAddons = false}) => {
    const { labelBreakdown, sitewidePromo } = useProductPricingDetails(productCode, includeAddons)
    const sitewideTheme = sitewidePromo?.theme

    if (!Array.isArray(labelBreakdown) || labelBreakdown.length === 0) {
        return null
    }

    const classes = classNames(styles.discountLabels,
        { [styles['discountLabels--amount']]: showDiscountAmount }
    )

    const formatDiscountAmount = (amount, label, productCode) => {
        if (typeof amount === 'number' && !isNaN(amount)) {
            // Rounding due to floating point issues with percent off discounts
            return `-$${Math.round(amount)}`
        } else {
            // This will be logged on the server
            logger.error({
                message: `Error rounding discount amount for discount ${label} on PDP: ${productCode}`,
                details: {
                    PDP: productCode,
                    discountLabel: label,
                    discountAmount: amount
                }
            })
            // Include the discount, but don't specify the amount
            return ''
        }
    }

    return (
        <div className={classes}>
            {labelBreakdown.map(label => (
                <DiscountLabel
                    key={label.label}
                    text={label.label}
                    discountType={label.discountType}
                    amount={showDiscountAmount ? `${formatDiscountAmount(label.discountAmount)}` : null}
                    theme={sitewideTheme}
                />
            ))}
        </div>
    )
}

DiscountLabels.propTypes = {
    productCode: string.isRequired
}

export default DiscountLabels
