import get from 'lodash/get'
import { Cookies } from '@saatva-bits/pattern-library.utils.cookies'

export const buildProductDetailsForEEC = (products, isFullDetail, dimension = null) => {
    if (products.length === 0) {
        return null
    }
    const validProducts = products.filter(product => Boolean(product))

    return validProducts.map(product => {
        const isCartData = !!product.productAttributes
        let specificProductData = product.selectedOptionProduct ? product.selectedOptionProduct : product

        const {
            sku,
            price
        } = specificProductData

        const {
            quantity
        } = product

        if (!isCartData) {
            specificProductData.parentSku = product.parentSku || product.sku
        }

        const baseDetails = {
            item_id: sku,
            item_name: product.label,
            item_category: product.category,
            location_id: dimension || 'pdp',
        }

        if (isFullDetail) {
            return {
                ...baseDetails,
                item_variant: product.label,
                price: price,
                quantity
            }
        } else {
            return baseDetails
        }
    })
}

export const updateProductCategories = (products, cartItems) => {
    const saatvaProductTypes = getSaatvaProductTypes(cartItems)

    return products.map((product) => {
        const category = saatvaProductTypes[product.id] || product.category

        return {
            ...product,
            category
        }
    })
}

const getSaatvaProductTypes = (cartItems) => {
    const saatvaProductTypes = {}

    if (cartItems) {
        cartItems.forEach(item => {
            const sku = get(item, 'sku')
            const saatvaProductType = item.magentoProductType
            if (sku && saatvaProductType) {
                saatvaProductTypes[sku] = saatvaProductType
            }
        })
    }

    return saatvaProductTypes
}

function buildAlgoliaMetaData(cart, addedItems) {
    const gaCookieValue = Cookies.get('_ga')
    const gaUserIdFromCookie = gaCookieValue?.split('.')?.slice(-2)?.join('_')
    const recentlyAdded = cart.items.filter(item => addedItems.some(addedItem => addedItem.sku === item.sku)).map(product => {
        const quantity = addedItems.find(addedItem => addedItem.sku === product.sku).quantity
        return { ...product, addedQuantity: quantity }
    })

    return {
        algoliaUserToken: gaUserIdFromCookie || null,
        indexName: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX,
        currency: 'USD',
        value: recentlyAdded.reduce((sum, product) => sum + product.price * product.addedQuantity, 0),
        objectIds: recentlyAdded.map(product => product.productCode),
        objectData: recentlyAdded.map(product => {
            return {
                sku: product.sku,
                objectId: product.productCode,
                quantity: product.addedQuantity,
                price: product.price,
                category: product.category,
                discounts: 0
            }
        })
    }
}

/**
 * Analytics event that is triggered when user comes from algolia search tile and adds to cart
 */
export function pushSearchProductAddToCart(cart, addedItems) {
    try {
        window.dataLayer.push({
            event: 'searchProductsAddedToCartPDP',
            eventName: 'PDP Products Added to Cart from Search',
            ...buildAlgoliaMetaData(cart, addedItems)
        })
    } catch (error) {
        console.error(`[Algolia Search PDP Add to Cart]: ${error}`)
    }
}

/**
 * Analytics event that is triggered when user comes from a algolia trending tile and adds to cart
 */
export function pushTrendingProductAddToCart(cart, addedItems) {
    try {
        window.dataLayer.push({
            event: 'algoliaTrendingProductsAddedToCartPDP',
            eventName: 'PDP Products Added to Cart after clicking trending item',
            ...buildAlgoliaMetaData(cart, addedItems)
        })
    } catch (error) {
        console.error(`[Algolia Trending PDP Add to Cart]: ${error}`)
    }
}

