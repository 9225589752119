import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ProductTitle from '@/components/ProductTitle'
import ProductPrice from '@/components/ProductPrice'
import styles from './Buystack.module.scss'
import { ProductSelectors, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import Addon from '@/components/Addon'
import YotpoReviewStars from '@/components/YotpoReviewStars'
import ProductLongDescription from '@/components/ProductLongDescription'
import DeliveryEstimate from '@/components/DeliveryEstimate'
import ProductPromoCallout from '@/components/ProductPromoCallout'
import ProductSubtotal from '@/components/ProductSubtotal'
import PromoBanner from '@/components/PromoBanner'
import useDeviceType from '@/hooks/useDeviceType'
import DeliveryAndSetup from '@/components/DeliveryAndSetup/DeliveryAndSetup'
import DiscountLabels from '@/components/DiscountLabels'
import AffirmMessage from '@/components/AffirmMessage'
import useSelectorProps from '@/hooks/useSelectorProps'
import { Global } from '@/contexts'
import EpigraphModelViewer from '@/components/EpigraphModelViewer'
import { PRODUCT_COLLECTIONS } from '@/constants'
import MattressFirmnessModal from '@/components/MattressFirmnessModal'
import ProductWhatsIncluded from '@/components/ProductWhatsIncluded'
import PersonalizationPanel from '@/components/PersonalizationPanel'
import CrossSellLinkToggle from '@/components/CrossSellLinkToggle'
import CrossSellsBuystackWrapper from '@/components/CrossSells/partials/CrossSellsBuystackWrapper/CrossSellsBuystackWrapper'
import QuantityAndAddToCart from '../QuantityAndAddToCart/QuantityAndAddToCart'
import BuystackAdditionalTooling from '../BuystackAdditionalTooling'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'

const Buystack = () => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { isDesktop } = useDeviceType('desktop')
    const selectorProps = useSelectorProps(productCode)
    const { isBundleProduct, variants } = useProductData(productCode)
    const { isV1: showDiscountLabels } = useExperiment('EXP.PROMOTIONS_MSGING.EX-450')

    useEffect(() => {
        if (variants) {
            const hasOOSVariant = variants.some(variant => variant.inStock === false)
            if (hasOOSVariant) {
                window.dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'oosVariantPdp'
                })
            }
        }
    },[variants])

    const buystackClasses = classNames(styles.buystack, 'section', 'u-paddingVertical--none')

    const showArButton = PRODUCT_COLLECTIONS.BEDFRAMES.includes(productCode) || PRODUCT_COLLECTIONS.PLATFORM_BEDFRAMES.includes(productCode)
    const epigraphArButtonProps = {
        EpigraphModelViewer: showArButton && <EpigraphModelViewer productCode={productCode} isArButtonOnly={true} />
    }

    return (
        <section id="buystack" className={buystackClasses}>
            <ProductTitle productCode={productCode} />
            {!isBundleProduct && <YotpoReviewStars
                productCode={productCode}
                enableEarlyReviewMessage={true}
                showBadge
                className={'u-marginTop--2xs'}
            />}
            <ProductPrice productCode={productCode} />
            {showDiscountLabels &&
                <DiscountLabels productCode={productCode}
                    showDiscountAmount={false}
                    includeAddons={false}
                />
            }
            {!showDiscountLabels && <ProductPromoCallout productCode={productCode} />}
            <PersonalizationPanel productCode={productCode} />
            <CrossSellLinkToggle productCode={productCode} />
            <ProductSelectors
                productCode={productCode}
                customOptionProps={selectorProps}
                dataSelectorModifier='buystack'
                swatchesSize='medium'
                hiddenAttributes={['quantity']}
                epigraphArButtonProps={epigraphArButtonProps}
                isOutOfStockStyling={!isBundleProduct}
            />
            {productCode === SAATVA_CLASSIC && <BuystackAdditionalTooling productCode={productCode}/>}
            <MattressFirmnessModal />
            <ProductWhatsIncluded productCode={productCode} />
            <Addon productCode={productCode} />
            <ProductLongDescription productCode={productCode} />
            <ProductSubtotal productCode={productCode} />
            {!showDiscountLabels && <AffirmMessage productCode={productCode} className={'u-textRight'} />}
            <QuantityAndAddToCart productCode={productCode} />
            {showDiscountLabels && <AffirmMessage productCode={productCode} />}
            <CrossSellsBuystackWrapper
                crossSellData='lookingSimilar'
                title='Discover other great options'
                classes='u-padding--none'
                isCompact
            />
            <DeliveryEstimate productCode={productCode} />
            {!showDiscountLabels && <PromoBanner productCode={productCode} />}
            {/* EX-134: We always want to show service value props here on mobile */}
            {!isDesktop && <DeliveryAndSetup productCode={productCode} />}
            {/* EX-134 END */}
        </section>
    )
}

Buystack.propTypes = {
    productCode: PropTypes.string,
    category: PropTypes.string
}

export default Buystack
