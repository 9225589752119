import { PRODUCT_CODES } from '@/constants'

const NAV_SECTION_MAPPINGS = {
    overview: {
        id: 'overview',
        name: 'Overview'
    },
    layers: {
        id: 'layers',
        name: 'Layers'
    },
    details: {
        id: 'details',
        name: 'Details'
    },
    specs: {
        id: 'specs',
        name: 'Specs'
    },
    reviews: {
        id: 'reviews',
        name: 'Reviews'
    },
    cta: {
        id: 'productPanel',
        name: 'Choose mattress size',
        isCta: true,
        colorModifier: 'blue'
    },
}

const STANDARD_MATTRESS_LINKS = [
    NAV_SECTION_MAPPINGS.overview,
    NAV_SECTION_MAPPINGS.layers,
    NAV_SECTION_MAPPINGS.details,
    NAV_SECTION_MAPPINGS.specs,
    NAV_SECTION_MAPPINGS.reviews,
    NAV_SECTION_MAPPINGS.cta,
]

const YOUTH_MATTRESS_LINKS = [
    NAV_SECTION_MAPPINGS.overview,
    NAV_SECTION_MAPPINGS.layers,
    NAV_SECTION_MAPPINGS.specs,
    NAV_SECTION_MAPPINGS.reviews,
    NAV_SECTION_MAPPINGS.cta,
]

// Used to generate the sticky nav links on PDPs
const linkConfig = {
    // Standard mattress links
    [PRODUCT_CODES.SAATVA_LATEX_HYBRID]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.SAATVA_CLASSIC]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.LOOM_AND_LEAF]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.SAATVA_CONTOUR5]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.SOLAIRE]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.MEMORY_FOAM_HYBRID]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.SAATVA_RX]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.ZENHAVEN]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.SAATVA_HD]: STANDARD_MATTRESS_LINKS,
    [PRODUCT_CODES.CRIB_MATTRESS]: STANDARD_MATTRESS_LINKS,
    // Youth mattress links, doesn't include "Details" section
    [PRODUCT_CODES.BUNK_TRUNDLE_YOUTH]: YOUTH_MATTRESS_LINKS,
    [PRODUCT_CODES.SAATVA_YOUTH]: YOUTH_MATTRESS_LINKS,
    // The remaining special case, doesn't include "Reviews" section
    [PRODUCT_CODES.DOG_BED]: [
        NAV_SECTION_MAPPINGS.overview,
        NAV_SECTION_MAPPINGS.details,
        NAV_SECTION_MAPPINGS.layers,
        NAV_SECTION_MAPPINGS.specs,
        NAV_SECTION_MAPPINGS.cta,
    ],
    [PRODUCT_CODES.MURPHY_BED_MATTRESS]: YOUTH_MATTRESS_LINKS
}

export { linkConfig }
