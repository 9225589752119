const { useLearnMoreModalContext } = require('@/contexts/learnMoreModal')

const useLearnMoreModal = () => {
    const { isModalOpen, setIsModalOpen } = useLearnMoreModalContext()

    return {
        isModalOpen,
        setIsModalOpen
    }
}

export default useLearnMoreModal
