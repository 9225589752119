import { useEffect, useState, useRef, useMemo } from 'react'
import logger from '@saatva-bits/pattern-library.utils.logger'

/**
 * Observe when an element enters the viewport.
 * @param {Object} options - Configuration options for Intersection Observer.
 *        - root: The element that is used as the viewport (default: null, meaning the browser viewport).
 *        - rootMargin: A margin around the root (e.g., "0px", "50px" to trigger earlier).
 *        - threshold: A number between 0 and 1 indicating what percentage of the element must be visible to trigger.
 * @returns {Array} [ref, isVisible] - A ref to attach to the element and a boolean indicating the element is visible.
 */
const useInView = (options = {
    root: null, // Default: Entire viewport
    rootMargin: '0px',
    threshold: 0.5 // Default: 50% visibility
}) => {
    // Is element in viewport?
    const [isVisible, setIsVisible] = useState(false)
    // A ref to attach to the target DOM element
    const ref = useRef(null)

    // Memoize the options to prevent unnecessary re-renders
    const memoizedOptions = useMemo(() => options, [options.root, options.rootMargin, options.threshold])

    useEffect(() => {
        // Prevent running on server
        if (typeof window === 'undefined') {
            return
        }

        // Prevent running if browser does not support Intersection Observer
        if (!('IntersectionObserver' in window)) {
            logger.info({
                message: 'Intersection Observer API is not supported in this browser.',
                location: 'hooks.useIntersectionObserver',
            })
            return
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true)
                observer.disconnect() // Stop observing after first trigger
            }
        }, memoizedOptions)

        // Start observing the element when it is assigned to the ref
        if (ref.current) {
            observer.observe(ref.current)
        }

        // Disconnect observer when component unmounts or re-renders
        return () => {
            observer.disconnect()
        }
    }, [memoizedOptions])

    return [ref, isVisible]
}

export default useInView
