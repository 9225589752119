import React, { useState, useContext } from 'react'
import { string } from 'prop-types'

import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { PRODUCT_CODES as PC } from '@/constants'

import { Global } from '@/contexts'
import { ScaleComfortLevels } from '@/components/TabbedContentDisplay'
import ScaleComfortConfig from '@/components/TabbedContentDisplay/ScaleComfortLevelConfig'
import DualSidedYouthMattressModal from '@/components/DualSidedYouthMattressModal'
import styles from './MattressFirmnessModal.module.scss'

const MattressFirmnessModal = () => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const productState = useProductState(productCode)

    const controlComponents = {
        [PC.SAATVA_YOUTH]: DualSidedYouthMattressModal,
        [PC.ZENHAVEN]: ScaleComfortLevels
    }

    // BEGIN A/B Test: PDP Comfort Level on Buystack EX-218
    const { isV1: isComfortLevelTestV1, isV2: isComfortLevelTestV2 } = useExperiment('EXP.COMFORT_BUYSTACK_v2.EX-443')
    const testContent = {
        [PC.ZENHAVEN]: { scale: '(Flippable)' },
        [PC.SAATVA_HD]: { scale: '(5-7/10)' },
        [PC.SAATVA_RX]: { scale: '(3-4/10)' },
        [PC.SAATVA_LATEX_HYBRID]: { scale: '(6/10)' },
        [PC.MEMORY_FOAM_HYBRID]: { scale: '(5-7/10)' },
        [PC.SOLAIRE]: { scale: '(50 firmness settings)', modalProps: { defaultActiveIndex: 1 } }
    }

    const isTestExperience = Boolean(isComfortLevelTestV1 || isComfortLevelTestV2) && testContent[productCode]
    const ModalComponent = isTestExperience ? ScaleComfortLevels : controlComponents[productCode]
    if (!ModalComponent) {
        return null
    }

    const onModalClose = () => {
        setIsModalVisible(false)
    }

    return isTestExperience ? (
        <div class="u-textLeft">
            <span class="t-link--independent">
                Mattress firmness
            </span>
            <div
                data-selector='buystackFirmness'
                onClick={() => setIsModalVisible(true)}
                className={`${styles.firmnessWrapper} t-base u-flexDisplay u-flexJustify--spaceBetween u-marginTop--2xs u-marginBottom`}
            >
                <span>{productState.comfortLevel} {isComfortLevelTestV1 && <span className="t-light">{testContent[productCode].scale}</span>}</span>
                <button className={`t-link t-link--independent ${styles.modalToggle}`}>
                    How it feels
                </button>
            </div>
            <Modal
                isModalVisible={isModalVisible}
                type="popup"
                modalTitle={ScaleComfortConfig[productCode].heading}
                modalLabel="Comfort Level modal"
                onModalClose={onModalClose}
            >
                <ModalComponent {...(testContent[productCode]?.modalProps ?? {})} />
            </Modal>
        </div>
    ) : (
        <>
            <div className={`${styles.label} u-flexJustify--flexEnd u-marginTop--2xs u-marginBottom`}>
                <button className={`t-link t-link--independent t-link--action ${styles.modalToggle}`}
                    onClick={() => setIsModalVisible(true)}>
                    What's a dual-sided mattress?
                </button>
            </div>
            <Modal
                isModalVisible={isModalVisible}
                type="popup"
                modalTitle="Two comfort levels, one mattress"
                modalLabel="Comfort Level modal"
                onModalClose={onModalClose}
            >
                <ModalComponent />
            </Modal>
        </>
    )
}

MattressFirmnessModal.propTypes = {
    productCode: string.isRequired
}

export default MattressFirmnessModal
