import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ProductTitle.module.scss'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { ADJUSTABLE_BASE_PLUS, CLASSIC_STARTER_BUNDLE, SAATVA_CLASSIC } from '@/constants/product-codes'

const ProductTitle = ({
    productCode,
    className
}) => {
    const { name, content } = useProductData(productCode)
    const isAdjustableBasePlus = productCode === ADJUSTABLE_BASE_PLUS
    const hasAwardWinningIcon = productCode === SAATVA_CLASSIC || productCode === CLASSIC_STARTER_BUNDLE

    const classes = classNames(styles.header,
        'u-paddingTop--xs',
        'col--xs-12 col--md-10 col--lg-12 col--offset-lg-reset',
        className
    )
    const headingClasses = classNames(styles.titleContainer, 't-heading2')
    const shortDescriptionClasses = classNames('t-body')
    
    const title = <h1>
        {isAdjustableBasePlus
            ? <>Saatva Adjustable Base <span className={styles.adjustableBasePlus}>Plus</span></>
            : productCode === CLASSIC_STARTER_BUNDLE ? 'Classic Bundle' : name
        }
    </h1>
    
    return (
        <header className={classes}>
            <div className={headingClasses}>
                {title}
                {hasAwardWinningIcon && <SvgSprite className={styles.awardWinningIcon} spriteID='icon-award-winning' />}
            </div>
            <p className={shortDescriptionClasses}>
                {content.shortDescription}
            </p>
        </header>
    )
}

ProductTitle.propTypes = {
    productCode: PropTypes.string,
    className: PropTypes.string
}

export default ProductTitle
