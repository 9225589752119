import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'
import { useProductData, useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import { CATEGORIES } from '@/temp-configs/category-constants'
import DeliveryEstimateModal from './partials/DeliveryEstimateModal'
import styles from './DeliveryEstimate.module.scss'

const DeliveryEstimate = ({
    productCode
}) => {
    const { isOn: hideDeliveryEstimateBedding } = useFeatureFlag('HIDE_DELIVERY_ESTIMATES_BEDDING')
    const { category, deliveryEstimate } = useProductData(productCode)
    const { inStock: primaryProductInStock } = useProductState(productCode)
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (!primaryProductInStock || isEmpty(deliveryEstimate) || !deliveryEstimate) return null
    const { messageText, messageCta, modalHeader, modalContent } = deliveryEstimate

    const onModalClose = (sendExitEvent = true) => {
        setIsModalVisible(false)
        if (sendExitEvent) {
            const dataLayer = window?.dataLayer || []
            dataLayer.push({
                'event': 'exitDeliveryEstimateModal'
            })
        }
    }

    const handleFooterChatOnClick = () => {
        OpenChat()
        onModalClose(false)
    }
    const deliveryEstimateClasses = classNames(styles.deliveryEstimate)

    const modalFooter = (
        <div>
            <p className={styles.modal__footer__copy}>Need help with your order? We’re here to help 24/7</p>
            <p className={styles.modal__footer__copy}>
                <button data-selector='delivery-estimate-modal-chat' type='button' className='t-link t-link--action' onClick={handleFooterChatOnClick}>Chat</button>
                &nbsp; •  1-877-672-2882  • &nbsp;
                <a data-selector='delivery-estimate-modal-email' className='t-link t-link--action' href='mailto:info@saatvamattress.com' target='_self'>info@saatvamattress.com</a>
            </p>
        </div>
    )

    // These control the messaging on a per category basis.
    //No delivery estimate visible as final, live experience for Furniture and Mattress
    switch (true) {
        case category.toLowerCase() === CATEGORIES.MATTRESSES:
        case category.toLowerCase() === CATEGORIES.FURNITURE:{
            return null
        }
        case category.toLowerCase() === CATEGORIES.BEDDING && hideDeliveryEstimateBedding:{
            return null
        }
        default: {
            return (
                <div data-selector={`delivery-estimate-${productCode}`} className={deliveryEstimateClasses}>
                    <SvgSprite spriteID={'icon-small-truck'} />
                    <p className={`${styles.prompt} t-base`}>
                        {messageText}
                        &nbsp;
                        <button type='button' onClick={() => { setIsModalVisible(true) }} className={styles[`promt--buttonTime`]}> {messageCta}</button>
                    </p>
                    <Modal
                        type='popup'
                        isModalVisible={isModalVisible}
                        onModalClose={onModalClose}
                        titleClassName={styles.modal__header}
                        modalTitle={modalHeader}
                        modalLabel='delivery-estimate-modal'
                        modalFooter={modalFooter}
                        className={styles.modal}
                        contentWrapperClassName={styles.modal__content}
                        footerClassName={styles.modal__footer}
                    >
                        <DeliveryEstimateModal content={modalContent}/>
                    </Modal>
                </div>
            )
        }
    }
}

DeliveryEstimate.propTypes = {
    productCode: PropTypes.string.isRequired
}

export default DeliveryEstimate
