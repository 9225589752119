import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { FormTextInput } from '@saatva-bits/pattern-library.components.form-text-input'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { useFeatureFlag, useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import logger from '@saatva-bits/pattern-library.utils.logger'

import { BASES, MATTRESSES, FRAME_TO_PLATFORM_SURPLUS_PRODUCT_CODES } from '@/constants/product-collections'
import styles from './OutOfStockMessage.module.scss'
import { phoneNumbers } from '@/constants/contact-info'
import { sendCordialEmailSubmissionEvents, handleCordialBisaInlineSubmission } from '@/utils/cordial'

const OutOfStockMessage = ({
    className,
    enableBackInStockAlerts = true,
    productCode,
    isOutOfStock
}) => {
    const { isOn: isPlatformBedFrameCtaEnabled } = useFeatureFlag('ENABLE_BEDFRAME_OOS.EX-158')
    const { isV0: phoneNumberTest } = useExperiment('EXP.NEED_HELP.EX-391')
    const { isV1: isBisaInline } = useExperiment('EXP.BISA_INLINE.EX-440')

    const { isBundleProduct } = useProductData(productCode)
    const { sku, genericName, limitedEdition: isLimitedEdition } = useProductState(productCode)
    const { surplusDiscountDefinitions } = usePromotionsData()
    const isClientSide = typeof window !== 'undefined' && typeof document !== 'undefined'
    const storage = storageWrapper()

    const localStorageKey = `${sku}-OOS-alert`
    const subscribedToSku = sku && isClientSide && storage.getItem(localStorageKey, 'local')
    const ctaInitialState = (isLimitedEdition || isBundleProduct) ? false : enableBackInStockAlerts
    const [hasCta, setHasCta] = useState(ctaInitialState)
    const [hasSubscribed, setHasSubscribed] = useState(subscribedToSku)
    const [intervalID, setIntervalID] = useState(0)
    const isFrameToPlatformProduct = FRAME_TO_PLATFORM_SURPLUS_PRODUCT_CODES.includes(productCode) && surplusDiscountDefinitions[productCode]?.discountValueType === 'percent'

    // Handles both Slick slider and updated carousel
    let cordialImageSrc = ''
    if (isClientSide) {
        // TODO: Update the product carousel to include the data selector there directly
        const image = document.querySelector('.productCarouselSlider img[class^="picture-imgix"]')
        const imagePath = image && image.getAttribute('src')
        if (imagePath) {
            cordialImageSrc = `${imagePath}`
        }
    }

    // INLINE BISA FORM

    const initialFormState = {
        email: '',
        optIn: false,
        submitStatus: 'unsubmitted'
    }
    const [formFields, setFormFields] = useState(initialFormState)

    const checkBoxClasses = classNames({
        // These need to be either-or due to how bit component applies classes to sub-elements
        [styles['checkbox']]: !formFields.optIn,
        [styles['checkbox--checked']]: formFields.optIn
    })

    const submitRequest = (e) => {
        e.preventDefault()

        try {
            handleCordialBisaInlineSubmission(window.location.href, formFields.email, sku, genericName, cordialImageSrc)

            // if all succeeds, set the same local storage key that Cordial does for the lightbox popup:
            storage.setItem(localStorageKey, true, 'local')

            // Update form fields for successful submission
            setFormFields({
                ...formFields,
                submitStatus: 'success'
            })
        } catch(error) {
            setFormFields({
                ...formFields,
                submitStatus: 'error'
            })
            logger.error({
                message: 'Error submitting inline BISA stock alert request',
                location: 'components.OutOfStockMessage.submitRequest',
                details: {
                    productCode,
                    sku,
                    genericName,
                    cordialImageSrc
                },
                error
            })
        }

        if (formFields.optIn) {
            try {
                sendCordialEmailSubmissionEvents(formFields.email, 'bisa')
            } catch(error) {
                // If this fails, log and move on. Don't interrupt the customer journey.
                logger.error({
                    message: 'Error submitting inline BISA optIn request',
                    location: 'components.OutOfStockMessage.submitRequest',
                    details: {
                        productCode,
                        sku,
                        genericName,
                        cordialImageSrc
                    },
                    error
                })
            }
        }
    }

    // END INLINE BISA FORM

    // When a user has subscribed to an out of stock email notification, third-party Cordial code
    // adds a key with named `${sku}-OOS-alert` with a value of true in localStorage. This checks for that key.
    const checkForSKUKey = () => {
        const skuKeyValue = storage.getItem(localStorageKey, 'local')
        if (skuKeyValue) {
            setHasSubscribed(true)
        }
    }

    const removeLocalStorageInterval = () => {
        if (intervalID !== 0) {
            clearInterval(intervalID)
            setIntervalID(0)
        }
    }

    // Once the user opens the BackInStockAlert modal, periodically check to see if the user has asked for back in stock alerts
    const handleClick = useCallback(() => {
        // Check every 1000ms to see whether the local storage key has been set
        if (isClientSide) {
            if (window.LIGHTBOX_API && window.LIGHTBOX_API.LIGHTBOX) {
                // the oos-clicked styles are added to the page via GTM
                window.LIGHTBOX_API.LIGHTBOX.closeAllOpenLightboxes()
                document.body.classList.add('oos-clicked')
                document.documentElement.classList.add('oos-clicked')
            }
            setIntervalID(setInterval(checkForSKUKey, 1000))
        }
    }, [sku])

    useEffect(() => {
        const isBaseProduct = BASES.includes(productCode)
        const isMattressProduct = MATTRESSES.includes(productCode)
        const isMadeToOrderProduct = isBaseProduct || isMattressProduct

        if (isMadeToOrderProduct) setHasCta(true)
        if (isFrameToPlatformProduct && !isPlatformBedFrameCtaEnabled) setHasCta(false)
        setHasSubscribed(subscribedToSku)
    }, [])

    // Reset hasSubscribed state on any variant change
    useEffect(() => {
        setHasSubscribed(subscribedToSku)
        setFormFields({...initialFormState})
        removeLocalStorageInterval()

        isOutOfStock && window.dataLayer.push({
            'event': 'outOfStock'
        })
    }, [sku])

    useEffect(() => {
        removeLocalStorageInterval()
    }, [hasSubscribed])

    const phoneNumber = phoneNumberTest ? phoneNumbers.phoneNumberV0 : phoneNumbers.phoneNumberV1 

    // Messaging and CTA
    // This element must have the `productIsOutOfStock` class in order for the cordial integration to work
    const outOfStockContainerClasses = classNames(styles.wrapper, 'productIsOutOfStock', {
        [className]: className
    })
    const outOfStockMessageClasses = classNames(styles.text, 't-bodySm')
    const buttonClasses = classNames(styles.button, 'u-bgColor--accentBlue')

    // Subscribed messaging
    const subscribedMessage = <p data-selector="out-of-stock-messaging" className={`${styles.text} t-base t-bodySm`}>
        We've received your request to be notified by email when this comes back in stock.
    </p>

    // CTA messaging
    const ctaMessage = <p className={outOfStockMessageClasses}>
        Sign up to be notified by email once this high-demand item is back in stock or call us for a timeframe quote at <a href={phoneNumber} className='t-underline'>{phoneNumber}</a>.
    </p>

    // Non-CTA Messaging
    const noCtaTimeframe = <p className={outOfStockMessageClasses}>
        Call us at <a href={phoneNumber} className='t-underline'>{phoneNumber}</a> for a timeframe quote of when this high-demand item will be back in stock.
    </p>
    const noCtaLimitedEdition = <>
        <p className={outOfStockMessageClasses}>This limited edition color is out of stock in this size.</p>
        <p className={outOfStockMessageClasses}>Please select another color or style.</p>
    </>
    const productText = isBundleProduct ? 'bundle' : 'item'
    const noCtaHighDemand = <>
        <p className={outOfStockMessageClasses}>This high demand {productText} is out of stock.</p>
        <p className={outOfStockMessageClasses}>For more info, call us at <a href={phoneNumber} className='t-underline'>{phoneNumber}</a>.</p>
    </>

    const noCtaMessage = isLimitedEdition
        ? noCtaLimitedEdition
        : (isFrameToPlatformProduct || isBundleProduct)
            ? noCtaHighDemand
            : noCtaTimeframe

    // Final Messaging
    const deducedMessage = hasSubscribed
        ? subscribedMessage
        : hasCta
            ? ctaMessage
            : noCtaMessage

    const isInlineFormSuccess = formFields.submitStatus === 'success'
    const isInlineFormError = formFields.submitStatus === 'error'
    const isShowCta = !isInlineFormSuccess && hasCta && !hasSubscribed

    // Clean up the localStorage key if the product is in stock, allowing the user to sign up again in the future.
    if (!isOutOfStock) {
        subscribedToSku && storage.removeItem(localStorageKey, 'local')
        return null
    }

    return (
        <div
            className={outOfStockContainerClasses}
            data-cordial-product-image={cordialImageSrc}
            data-cordial-product-name={genericName}
            data-cordial-product-sku={sku}>

            { isInlineFormSuccess ? (
                <div className={styles.inlineSubmittedWrapper}>
                    <p>You're on the list!</p>
                    <SvgSprite spriteID='icon-checkmark' className={styles.checkmark } />
                    <p>We’ll email you as soon as this product is back in stock.</p>
                </div>
            ) : (
                <div className={styles.message}>
                    {deducedMessage}
                </div>
            )}

            { isInlineFormError && (
                <p className={styles.errorMessage}>There was an error submitting your request. Please try again.</p>
            )}

            { isShowCta && (
                isBisaInline ? (
                    <form id="bisaForm" className={styles.inlineForm} onSubmit={submitRequest}>
                        <FormTextInput
                            id="bisa-email-input"
                            data-selector="bisa-email-input"
                            className={styles.textInput}
                            labelClassName={styles.textInput__label}
                            inputName="bisaEmail"
                            inputMode="email"
                            inputType="email"
                            isRequired
                            disableRequiredOnBlur
                            labelText="Enter your email address"
                            onTextChange={(e) => setFormFields({ ...formFields, email: e.target.value })}
                        />
                        <FormCheckbox
                            id="bisa-optIn-checkbox"
                            className={checkBoxClasses}
                            label={'I agree to receive emails from Saatva about the latest sales, product news & more.'}
                            checked={formFields.optIn}
                            onChange={(e) => setFormFields({ ...formFields, optIn: e.target.checked })}
                            data-selector="bisa-optIn-checkbox"
                            containerAttributes={{ 'data-selector': `bisa-optIn-container` }}
                        />
                        <span className={styles.termsLinks}>
                            <a href="https://privacy.saatva.com/privacy-policy" className="t-link">Privacy Policy</a> • <a href="https://privacy.saatva.com/privacy-policy#terms-of-use" className="t-link">Terms of Use</a>
                        </span>
                        <Button className={buttonClasses} kind="primary" type="submit">
                            Notify me when in stock
                        </Button>
                    </form>
                ) : (
                    <Button
                        kind='primary'
                        className={buttonClasses}
                        data-selector='back-in-stock-alert'
                        onClick={handleClick}
                    >
                        Email me when in stock
                    </Button>
                )
            )}
        </div>
    )
}

OutOfStockMessage.propTypes = {
    className: PropTypes.string,
    enableBackInStockAlerts: PropTypes.bool,
    productCode: PropTypes.string,
    isOutOfStock: PropTypes.bool.isRequired
}

export default OutOfStockMessage
