import FoundationConfig from './FoundationModalConfig'
import { useContext } from 'react'
import { Global } from '@/contexts'
import { SAATVA_CLASSIC, CLASSIC_STARTER_BUNDLE } from '@/constants/product-codes'

const FoundationModal = ({ type }) => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const config = FoundationConfig[productCode]

    if (!config) return null

    const { diagramImage, modalText, secondaryHeader, subtext } = config
    const hasSaatvaClassic = productCode === SAATVA_CLASSIC || productCode === CLASSIC_STARTER_BUNDLE

    const foundationClassName = `smMattressFoundationDiagram ${diagramImage}${hasSaatvaClassic ? `--${type === 'mattress' ? 'mattress' : 'foundation'}` : ''
        }`

    const sectionClassName = hasSaatvaClassic
        ? 'u-textCenter'
        : 'u-marginTop--2dot5xl u-marginBottom--lg'

    const singleImgClass = (size) => 
        `${diagramImage}__singleImg ${diagramImage}__singleImg--${size}`

    return (
        <section className={sectionClassName}>
            <div className={`${diagramImage}__intro`}>
                {subtext && (
                    <p className="u-textLeft u-marginBottom--xs smMattressFoundation__note">
                        {subtext}
                    </p>
                )}
                {secondaryHeader && (
                    <h3 className="t-heading4 u-marginBottom--sm">{secondaryHeader}</h3>
                )}
                <p className="u-textLeft" dangerouslySetInnerHTML={{ __html: modalText }} />
            </div>

            <div className={foundationClassName}>
                <div className="row">
                    <div className="col col--xs-12 col--md-4 col--offset-md-3">
                        <div className={singleImgClass('475')} />
                    </div>
                    <div className="col col--xs-12 col--md-4">
                        <div className={singleImgClass('875')} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FoundationModal
