import { getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

export const getArdadImage = (slot, variant, ardadDescriptors) => {
    const commonImageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        /** Override the lazyload offset added by the detail product tile from bit  */
        lazyLoadOffset: null,
        imgixDomain: process.env.NEXT_PUBLIC_IMGIX_URL
    }

    let folder, name, descriptor
    try {
        descriptor = ardadDescriptors.find( descriptorDef => descriptorDef.slot == slot ).descriptors[0]
        const ardadAsset = getAssetForVariant(variant, descriptor, '3-2')
        folder = ardadAsset.folder
        name = ardadAsset.file
    } catch (error) {
        logger.warn({
            message: 'Cannot find ARDAD asset for variant',
            location: 'components.CrossSells.utilities.getArdadImage',
            details: {
                productCode: variant.productCode,
                sku: variant.sku,
                slot,
                descriptor
            },
            error
        })
    }

    return {
        ...commonImageProps,
        folder,
        name,
        alt: variant.genericName,
        prefixOverride: {
            mobile: 'none',
            tablet: 'none',
            desktop: 'none'
        }
    }
}

/**
 * Transform the swatch options from catalog into the structure used in the productTile component for swatch selection
 * @param {array} options
 * @param {string} defaultOverride
 * @returns {object}
 */
export const transformSwatchOptionsForCarousel = (swatchOptions, onChange) => {
    let swatchObject = {
        label: '',
        initialSelection: '',
        size: 'small',
        optionsList: []
    }

    if (!swatchOptions) return swatchObject

    const optionsList = []
    swatchOptions.values.map((option) => {
        const swatchOption = {
            value: option.code,
            label: option.label,
            background: option.swatch,
            useInverseCheckColor: true
        }
        optionsList.push(swatchOption)
    })

    return {
        ...swatchObject,
        label: swatchOptions.displayName,
        initialSelection: swatchOptions.default,
        optionsList,
        onChange
    }
}
