import { Button } from '@saatva-bits/pattern-library.components.button'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import styles from './BuystackAdditionalTooling.module.scss'
import { useEffect, useState } from 'react'
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import classNames from 'classnames'
const IMGIX_VIDEO_URL = process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
import useLearnMoreModal from '@/hooks/useLearnMoreModal'

const BuystackAdditionalTooling = ({
    productCode,
    // This makes the component visible since the first render
    showAlways = false,
    backgroundColor = 'default',
    // This just render the video
    onlyShowVideo = false,
    onTriggerVideo,
    dataSelectorTakeQuiz = 'comfort-quiz-cta-buystack',
    dataSelectorWatchVideo = 'buystack-open-comfort-video'
}) => {
    const { comfortLevel } = useProductState(productCode)
    const { isV0: showBSTooling } = useExperiment('EXP.COMFORT_QUIZ.EX-220')

    // If the user opens the About comfort levels modal
    const { isModalOpen } = useLearnMoreModal()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [ comfortLevelArray, setComfortLevelArray ] = useState([comfortLevel])
    const [loading, setLoading] = useState(true)

    // Check if page is fully loaded
    useEffect(() => {
        setLoading(false)
    }, [])
  
    useEffect(() => {
        // Once the page is fully loaded, it starts adding the comfortLevel
        if (comfortLevel !== undefined && !loading) {
            setComfortLevelArray(prev => [...prev, comfortLevel])
        }
    }, [comfortLevel])

    // If the comfortLevel appears twice in the array, the component is render
    const hasDuplicateComfortLevel = () => {
        return new Set(comfortLevelArray).size !== comfortLevelArray.length
    }

    // This event is triggered in both v1 and v0 EXP.COMFORT_QUIZ.EX-220
    const isEventInDatalayer = (eventName) => {
        if(typeof window !== 'undefined') {
            return window.dataLayer.find(item => item.event === eventName) !== undefined
        }
    }

    const eventTriggered = () => {
        const eventExist = isEventInDatalayer('comfortQuizCtasVisible')
        if(typeof window !== 'undefined' && !eventExist) {
            window.dataLayer = window.dataLayer || []
            dataLayer.push({'event': 'comfortQuizCtasVisible'})
        }
    }

    if(hasDuplicateComfortLevel()) {
        eventTriggered()
    }
    // This code is duplicated because analytics wants the event just triggered for v1 when modal is clicked
    if(!showBSTooling && isModalOpen) {
        eventTriggered()
    }

    if(showBSTooling) {
        return null
    }



    const showTooling = isModalOpen || hasDuplicateComfortLevel() || showAlways

    const classNameWrapper = classNames(styles.buystackTooling, styles[`${backgroundColor}Background`], {
        [ styles.isvisible] : showTooling,
        [ styles.onlyShowVideo] : onlyShowVideo
    })

    const onModalToggle = () => {
        setIsModalVisible(!isModalVisible)
    }

    const renderVideo = () => {
        const videoClassName = classNames(styles.video, {
            [ styles.onlyShowVideo] : onlyShowVideo,
        })

        return (
            <VideoImgix
                aspectRatio='16-9'
                className={videoClassName}
                extension='mp4'
                filename='firmness'
                folder='saatva-classic'
                imgixOptions={{
                    fm: 'mp4'
                }}
                imgixSubdomain={IMGIX_VIDEO_URL}
                type='video/mp4'
                showComponentControls={false}
                videoProps={{
                    autoPlay: true,
                    playsInline: true,
                    controls: true,
                    preload: 'auto', // needs to be auto for Safari
                    width: 1280,
                    height: 720
                }}
            />
        )
    }

    return (
        <div className={classNameWrapper}>
            {!onlyShowVideo && <>
                <Button data-selector={dataSelectorTakeQuiz} kind='primary' target='_blank' className={styles.quizButton} href={'/comfort-quiz'}>Take the comfort quiz</Button>
                <span data-selector={dataSelectorWatchVideo} onClick={onTriggerVideo || onModalToggle}  className={`${styles.watchVideo} t-link`}>Watch the video</span>
                <Modal
                    type='popup'
                    isModalVisible={isModalVisible}
                    onModalClose={onModalToggle}
                    modalLabel='correct-mattress-fimness'
                >
                    {renderVideo()}
                </Modal>
            </>}
            {onlyShowVideo && renderVideo()}
        </div>
    )
}

export default BuystackAdditionalTooling
