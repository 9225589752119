import { useContext, useState, useEffect } from 'react'
import { Global } from '@/contexts'
import AddToFavoritesPanel from './AddToFavoritesPanel'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { CATEGORIES } from '@/temp-configs/category-constants'
import { useFavorites } from '@saatva-bits/pattern-library.modules.favorite'
import useDeviceType from '@/hooks/useDeviceType'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'

const FAVORITES_KEY = 'favorites'

const AddToFavoritesPanelWrapper = () => {
    const [shouldShow, setShouldShow] = useState(false)
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { category } = useProductData(productCode)
    // Default device type desktop prevents the panel from flashing on the PDP on desktop & tablet devices
    const { isMobile } = useDeviceType('desktop')

    const { isStorageAvailable } = useFavorites()
    const storage = storageWrapper()

    useEffect(() => {
        // Only run this check on the client side
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.href)
            const isViewingRoom = url.searchParams.get('utm_source') === 'viewingroom'
            const isQRCode = url.searchParams.get('utm_medium') === 'qrcode'

            if (isStorageAvailable) {
                /*
                  We're not using the getOneFavorite function because there is a moment where the isStorageAvailable
                  evaluates to true, but the favorites list in the favorites context is not yet up to date.
                  Instead, we are manually checking storage once we've confirmed that storage is available.
                */
                const storedFavorites = storage.getItem(FAVORITES_KEY, 'local')
                const isFavorite = storedFavorites?.productCodes?.includes(productCode) || false
                setShouldShow(isViewingRoom && isQRCode && isMobile && !isFavorite && category.toLowerCase() === CATEGORIES.MATTRESSES.toLowerCase())
            }
        }
    }, [isMobile, isStorageAvailable, category, productCode])

    if (!shouldShow) {
        return null
    }

    return <AddToFavoritesPanel />
}

export default AddToFavoritesPanelWrapper
