/* eslint-disable @next/next/no-img-element */
import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import { usePromotionsData, getDiscountAmount } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useProductData, useUpdateProductAttributes } from '@saatva-bits/pattern-library.modules.selection'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { Modal } from '@saatva-bits/pattern-library.components.modal'

import { PRODUCT_CODES } from '@/constants'
import { CATEGORIES } from '@/temp-configs/category-constants'
import useAddon from '@/hooks/addon'

import LegacyPicture from '@/components/LegacyPicture'
import DropdownPanel from '@/components/DropdownPanel'
import FoundationHeights from '@/components/FoundationHeightsModal'
import FoundationModal from '@/components/FoundationModal'
import StrikethroughPrice from '@/components/StrikethroughPrice'

import { TabList } from './partials/TabList'
import FoundationBaseInfoModal from './partials/FoundationBaseInfoModal/FoundationBaseInfoModal'
import IncludedAddonList from './partials/IncludedAddonList'
import ProductSelectToggle from './partials/ProductSelectToggle/ProductSelectToggle'
import { RelatedProductDropdownPanelAddonTile } from './partials/RelatedProductDropdownPanelAddonTile'

const {
    ADJUSTABLE_FRAME: FRAME_CODE,
    FOUNDATION,
    FOUNDATION_HD,
    ADJUSTABLE_BASE: ADJ_BASE_CODE,
    ADJUSTABLE_BASE_PLUS: ADJ_BASE_PLUS_CODE,
    ORGANIC_COTTON_SHEETS: SHEETS_CODE
} = PRODUCT_CODES

const { MATTRESSES: MATTRESSES_CATEGORY } = CATEGORIES

const FoundationBaseAddon = ({
    primaryProductCode,
    addonProductCodes,
    addonLabel
}) => {
    const dropdownRef = useRef(null)
    const [isClosing, setIsClosing] = useState(false)
    const [isClosed, setIsClosed] = useState(true)
    const [isSplitSize, setIsSplitSize] = useState(false)
    const [isUpdatedByUser, setIsUpdatedByUser] = useState(false) // distinguish between user and automatic actions from this component
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalContentName, setModalContentName] = useState('')

    const isHD = primaryProductCode === PRODUCT_CODES.SAATVA_HD
    const FOUNDATION_CODE = isHD ? FOUNDATION_HD : FOUNDATION // needed because the HD is a special case for its own foundation

    const { isAddonSelected: isFrameSelected, selectAddon: selectFrame, updateAddons } = useAddon(FRAME_CODE, addonLabel)
    const { isAddonSelected: isFoundationSelected } = useAddon(FOUNDATION_CODE, addonLabel)
    const { isAddonSelected: isBaseSelected } = useAddon(ADJ_BASE_CODE, addonLabel)
    const { isAddonSelected: isBasePlusSelected } = useAddon(ADJ_BASE_PLUS_CODE, addonLabel)
    const { selectAddon: selectSheet, unselectAddon: unselectSheet } = useAddon(SHEETS_CODE, addonLabel)

    const isAddonProductSelected = isFrameSelected || isFoundationSelected || isBaseSelected || isBasePlusSelected

    const updateProductAttributes = useUpdateProductAttributes()

    const { size: primarySize, name: primaryName, mattressHeight } = useProductState(primaryProductCode)
    const { category: primaryCategory, attributeSets: primaryAttributeSets } = useProductData(primaryProductCode)
    const { name: sabpName } = useProductData(ADJ_BASE_PLUS_CODE) || {}
    const { name: sabName } = useProductData(ADJ_BASE_CODE) || {}
    const { name: frameName } = useProductData(FRAME_CODE) || {}
    const { price: sabPrice } = useProductState(ADJ_BASE_CODE) || {}
    const { price: sabpPrice } = useProductState(ADJ_BASE_PLUS_CODE) || {}
    const { price: foundationPrice, sku: foundationSku } = useProductState(FOUNDATION_CODE) || {}
    const { price: framePrice } = useProductState(FRAME_CODE) || {}
    const { price: sheetPrice } = useProductState(SHEETS_CODE) || {}

    const mattressShortNames = {
        [PRODUCT_CODES.SAATVA_CLASSIC]: 'Saatva Classic',
        [PRODUCT_CODES.ZENHAVEN]: 'Zenhaven',
        [PRODUCT_CODES.LOOM_AND_LEAF]: 'Loom & Leaf',
        [PRODUCT_CODES.SAATVA_LATEX_HYBRID]: 'Saatva Latex Hybrid',
        [PRODUCT_CODES.SAATVA_YOUTH]: 'Saatva Youth',
        [PRODUCT_CODES.MEMORY_FOAM_HYBRID]: 'Memory Foam Hybrid',
        [PRODUCT_CODES.SOLAIRE]: 'Solaire',
        [PRODUCT_CODES.SAATVA_RX]: 'Saatva Rx',
        [PRODUCT_CODES.SAATVA_HD]: 'Saatva HD',
    }

    const mattressShortName = mattressShortNames[primaryProductCode] || primaryName
    const [baseTabErrorMessage, setBaseTabErrorMessage] = useState(null)
    const [buystackErrorMessage, setBuystackErrorMessage] = useState(null)

    const { foundationHeight = '' } = useProductState(FOUNDATION_CODE) || {}

    const includeFrame = addonProductCodes.includes(FRAME_CODE)
    const includeFoundation = addonProductCodes.includes(FOUNDATION_CODE)
    const includeAdjBase = addonProductCodes.includes(ADJ_BASE_CODE)
    const includeAdjBasePlus = addonProductCodes.includes(ADJ_BASE_PLUS_CODE)

    const showFrame = includeFrame
    const [showFoundation, setShowFoundation] = useState(includeFoundation)
    const [showAdjBase, setShowAdjBase] = useState(includeAdjBase)
    const showAdjBasePlus = includeAdjBasePlus

    const toggleFrame = (on) => {
        if (on) {
            updateAddons({
                addCodes: [FRAME_CODE]
            })
            selectFrame()
        } else {
            updateAddons({
                removeCodes: [FRAME_CODE]
            })
        }
    }

    const toggleFoundation = (on) => {
        if (on) {
            updateAddons({
                addCodes: [FOUNDATION_CODE],
                removeCodes: [ADJ_BASE_CODE, ADJ_BASE_PLUS_CODE]
            })
        } else {
            updateAddons({
                removeCodes: [FOUNDATION_CODE, FRAME_CODE]
            })
        }
    }

    const toggleBase = (on) => {
        if (on) {
            updateAddons({
                addCodes: [ADJ_BASE_CODE],
                removeCodes: [ADJ_BASE_PLUS_CODE, FOUNDATION_CODE, FRAME_CODE]
            })
        } else {
            updateAddons({
                removeCodes: [ADJ_BASE_CODE]
            })
        }
    }

    const toggleBasePlus = (on) => {
        if (on) {
            updateAddons({
                addCodes: [ADJ_BASE_PLUS_CODE],
                removeCodes: [ADJ_BASE_CODE, FOUNDATION_CODE, FRAME_CODE]
            })
        } else {
            updateAddons({
                removeCodes: [ADJ_BASE_PLUS_CODE]
            })
        }
    }

    const toggleAllOff = () => {
        updateAddons({
            removeCodes: [ADJ_BASE_CODE, ADJ_BASE_PLUS_CODE, FOUNDATION_CODE, FRAME_CODE]
        })
    }

    const isMattressPdp = primaryCategory.toLowerCase() === MATTRESSES_CATEGORY

    const isKingOrCalKingSize = primarySize === 'King' || primarySize === 'Cal King'

    const hasFoundationTab = showFoundation && includeFoundation
    const hasBaseTab = includeAdjBase || includeAdjBasePlus
    const [activeTab, setActiveTab] = useState(0)

    const baseTab = <div key="base" className="standardTab">{includeAdjBase && includeAdjBasePlus ? 'Adjustable Bases' : 'Adjustable Base'}</div>
    const foundationTab = <div key="foundation" className="standardTab">{isHD ? 'HD Foundation' : 'Foundation'}</div>

    const foundationPanel = useRef(null)
    const adjustableBasesPanel = useRef(null)

    useEffect(() => {
        if (primarySize === 'Split Cal King' || primarySize === 'Split King') {
            setIsSplitSize(true)
        } else {
            setIsSplitSize(false)
        }

        // on non-mattress pdp's, only the 4.75" mattress height is available
        if (!isMattressPdp && showFoundation) {
            updateProductAttributes(FOUNDATION_CODE, { foundationHeight: '4.75' })
        }

        // const productsWithAdjustableBaseDefaultOption = ['saatva-classic', 'memory-foam-hybrid', 'saatva-latex-hybrid']
        const upperflexSizes = ['Upper-Flex Queen', 'Upper-Flex King']
        const foundationIncompatibleSizes = upperflexSizes
        const adjustableBaseIncompatibleSizes = ['Twin', 'Full', 'Split Cal King', 'Upper-Flex Queen', 'Upper-Flex King']

        if (!foundationIncompatibleSizes.includes(primarySize) && includeFoundation) {
            setShowFoundation(true)
            // select foundation variant based on primary product size
            updateProductAttributes(FOUNDATION_CODE, { size: primarySize })
        } else {
            toggleFoundation(false)
            setShowFoundation(false)
        }

        if (!adjustableBaseIncompatibleSizes.includes(primarySize) && includeAdjBase) {
            setShowAdjBase(true)
            // select sab variant based on primary product size
            updateProductAttributes(ADJ_BASE_CODE, { size: primarySize })
            setBuystackErrorMessage(null)
        } else if (adjustableBaseIncompatibleSizes.includes(primarySize) && isBaseSelected) {
            // if either base is selected, we can safely toggle all off because foundation and metal frame can't be selected at the same time
            toggleAllOff()
            setShowAdjBase(false)
            setBuystackErrorMessage(`Adjustable base is <span class='t-italic'>not</span> available for the mattress size selected. Base removed.`)
        } else if (adjustableBaseIncompatibleSizes.includes(primarySize)) {
            setShowAdjBase(false)
        }

        // user gets a free sheet set when selecting the upper flex queen or king size.
        if (primarySize === 'Upper-Flex Queen' || primarySize === 'Upper-Flex King') {
            selectSheet()
            toggleBasePlus(true)
        } else {
            unselectSheet()
        }

        // Remove the SABP if automatically added by the component and the user changes the mattress
        // size to something that isn't Upper-Flex. If the user manually added it, leave it selected.
        if (isBasePlusSelected && !isUpdatedByUser && !upperflexSizes.includes(primarySize)) {
            toggleBasePlus(false)
        }

        // select sabp variant based on primary product size
        updateProductAttributes(ADJ_BASE_PLUS_CODE, { size: primarySize })
    }, [primarySize])

    useEffect(() => {
        if (hasFoundationTab && hasBaseTab) {
            if (activeTab === 0) {
                adjustableBasesPanel?.current?.classList?.remove('active')
                foundationPanel?.current?.classList?.add('active')
            } else {
                foundationPanel?.current?.classList?.remove('active')
                adjustableBasesPanel?.current?.classList?.add('active')
            }
        } else {
            if (hasFoundationTab) {
                adjustableBasesPanel?.current?.classList?.remove('active')
                foundationPanel?.current?.classList?.add('active')
            } else {
                foundationPanel?.current?.classList?.remove('active')
                adjustableBasesPanel?.current?.classList?.add('active')
            }
        }
    }, [activeTab, hasFoundationTab, hasBaseTab])

    useEffect(() => {
        if ((isBaseSelected || isBasePlusSelected) && mattressHeight === '14.5') {
            // TODO: toggle selector needs to update as well
            updateProductAttributes(primaryProductCode, { mattressHeight: '11.5' })
            setBaseTabErrorMessage(`Adjustable base is only compatible with 11.5" ${mattressShortName}. We have adjusted your selection.`)
        } else {
            setBaseTabErrorMessage(null)
        }
    }, [isBaseSelected, isBasePlusSelected])

    useEffect(() => {
        if ((isBaseSelected || isBasePlusSelected) && mattressHeight === '14.5') {
            // if either base is selected, we can safely toggle all off because foundation and metal frame can't be selected at the same time
            toggleAllOff()
            setBuystackErrorMessage(`14.5" ${mattressShortName} is <span class='t-italic'>not</span> compatible with adjustable bases. Base removed.`)
        } else {
            setBuystackErrorMessage(null)
        }
    }, [mattressHeight])

    const tabList = []
    if (hasFoundationTab) tabList.push(foundationTab)
    if (hasBaseTab) tabList.push(baseTab)

    const tablistClasses = classNames({
        'u-flexDisplay': tabList.length < 2
    }, 'foundationBaseAddon__tabList foundationBaseAddon__tabList__addonTile')

    const tabClasses = classNames('foundationBaseAddon__tab')
    const tabSliderClasses = classNames('foundationBaseAddon__tabList__slider', {
        'foundationBaseAddon__tabList__sliderLeft': activeTab === 0,
        'foundationBaseAddon__tabList__sliderRight': activeTab === 1
    })

    const tabs =
        tabList.length > 1 &&
            <TabList
                className={tablistClasses}
                tabClassName={tabClasses}
                sliderClassName={tabSliderClasses}
                key={true}
                activeIndex={activeTab}
                list={tabList}
                onTabClick={(e) => setActiveTab(e.tabIndex)}
            />

    const dropdownToggleCallback = (e, isOpen) => {
        const dropdownPanel = e.target.closest('.foundationBaseAddon__dropdown')

        if (!isOpen) {
            dropdownPanel.addEventListener('animationend', setDropdownClosingToFalse)
            dropdownPanel.addEventListener('transitionend', setDropdownClosingToFalse)
        }

        setIsClosing(!isOpen)
        isOpen && setIsClosed(false)
    }

    const setDropdownClosingToFalse = (e) => {
        const dropdownPanel = e.target.closest('.foundationBaseAddon__dropdown')
        dropdownPanel.removeEventListener('animationend', setDropdownClosingToFalse)
        dropdownPanel.removeEventListener('transitionend', setDropdownClosingToFalse)
        setIsClosing(false)
        setIsUpdatedByUser(true) // used for removing SABP when not added by the user for the Zenhaven
        setIsClosed(true)
    }

    const handleRemove = (e) => {
        e.preventDefault()
        e.stopPropagation()
        toggleAllOff()
        setIsUpdatedByUser(false) // reset so the SABP can be removed automatically if the user changes the mattress size
    }

    // Remove text is displayed if the modal is closed and an addon is selected.
    const removeText = () => {
        return (
            <span
                onClick={handleRemove}
                onKeyDown={(e) => e.key === 'Enter' && handleRemove(e)}
                role='button'
                tabIndex='0'
                className='t-underline'>
                Remove
            </span>
        )
    }

    // --- value discounts ---
    // SABP has discount when bought with mattress - usually $100
    // foundation has discount when bought with a mattress - usually $20 to $40 depending upon mattress size
    // These value discounts are only available when purchasing a mattress
    const {
        isValueDiscountEligible,
        valueDiscountDefinitions
    } = usePromotionsData()
    const hasValueDiscounts = isMattressPdp && isValueDiscountEligible // redefine eligibility with new const in case other factors disable discount
    const sabpValueDiscountAmount = hasValueDiscounts && getDiscountAmount(valueDiscountDefinitions.adjustableBaseMattress.discountValue)
    const foundationValueDiscountAmount = hasValueDiscounts && getDiscountAmount(valueDiscountDefinitions.foundationMattress.discountValue, foundationSku)
    // --- End value discounts ---

    // --- dropdown text ---
    const addedSpan = `<span class='t-italic t-bold foundationBaseAddon__dropdown__text__added'>Added</span>`

    const hasBothBaseValueDiscounts = sabpValueDiscountAmount && foundationValueDiscountAmount
    const maxDiscount = hasBothBaseValueDiscounts
        ? Math.max(foundationValueDiscountAmount, sabpValueDiscountAmount)
        : sabpValueDiscountAmount || foundationValueDiscountAmount

    const saveHtml = !isAddonProductSelected && isMattressPdp && maxDiscount
        ? ` <span class='foundationBaseAddon__discountText'>Save up to ${formatCurrency(maxDiscount)}</span>`
        : ''

    let dropdownHtml = `Add Foundation or Adjustable Base ${saveHtml}`

    if (isFoundationSelected && isFrameSelected) {
        dropdownHtml = `${addedSpan} &nbsp;${isHD ? 'HD ' : ''}Foundation + Frame`
    } else if (isFoundationSelected) {
        dropdownHtml = `${addedSpan} &nbsp;${isHD ? 'HD ' : ''}Foundation`
    } else if (isBaseSelected) {
        dropdownHtml = `${addedSpan} &nbsp;${sabName}`
    } else if (isBasePlusSelected) {
        dropdownHtml = `${addedSpan} &nbsp;${sabpName}`
    } else if (hasFoundationTab && hasBaseTab) {
        dropdownHtml = `Add ${isHD ? 'HD ' : ''}Foundation or Adjustable Base ${saveHtml}`
    } else if (hasFoundationTab) {
        dropdownHtml = `Add ${isHD ? 'HD ' : ''}Foundation ${saveHtml}`
    } else if (hasBaseTab) {
        dropdownHtml = `Add ${isHD ? 'HD ' : ''}Adjustable Base ${saveHtml}`
    }

    dropdownHtml = `<div>${dropdownHtml}</div>`

    // --- end dropdown text ---
    // --- footnotes ---
    const genericFootnote = `${mattressShortName} is compatible with most foundations, box springs, platform beds, and adjustable bases.`

    const footnotes = {
        default: {
            footnote: genericFootnote,
            useBulletList: false
        },
        [PRODUCT_CODES.SAATVA_CLASSIC]: {
            footnote: [
                'Saatva Classic is compatible with most foundations, box springs, and bed frames.',
                'Only the 11.5" height Saatva Classic is compatible with an adjustable base.'
            ],
            useBulletList: true
        },
        [PRODUCT_CODES.SAATVA_HD]: {
            footnote: 'Saatva HD is compatible with our HD foundation, platform beds, and adjustable bases.',
            useBulletList: false
        },
        [PRODUCT_CODES.SOLAIRE]: {
            footnote: [
                'Solaire is compatible with most foundations, box springs, platform beds, and adjustable bases.',
                'Solaire Upper-Flex pairs exclusively with our Adjustable Base Plus Upper-Flex',
                'Solaire Split King or Split Cal King pairs exclusively with our Adjustable Base Plus Split King or Cal-King'
            ],
            useBulletList: true
        },
        [PRODUCT_CODES.ZENHAVEN]: {
            footnote: [
                `Zenhaven requires a solid base. It's compatible with most foundations, box springs, solid platform beds, and adjustable bases.`,
                'Zenhaven is NOT compatible with slatted bed frames.'
            ],
            useBulletList: true
        }
    }

    const hasCustomFootnote = Boolean(footnotes[primaryProductCode])

    const displayFootnote = (footnote, useBulletList) => {
        if (!useBulletList) {
            return footnote
        }
        const footnoteClasses = classNames('foundationBaseAddon__list')

        return (
            <ul className={footnoteClasses}>
                {footnote.map(listItem => (
                    <li key={listItem}>{listItem}</li>
                ))}
            </ul>
        )
    }

    const footnoteData = hasCustomFootnote
        ? footnotes[primaryProductCode]
        : footnotes.default

    const footnote = () => {
        if (isMattressPdp) {
            return (
                <div className="foundationBaseAddon__bottomText">
                    {displayFootnote(footnoteData.footnote, footnoteData.useBulletList)}
                </div>
            )
        } else {
            return null
        }
    }

    // --- end footnotes ---
    const dropdownProps = {
        wrapperClassName: classNames('foundationBaseAddon__dropdownPanelWrapper u-marginBottom--lg'),
        className: classNames({
            'is-closing': isClosing
        }, 'foundationBaseAddon__dropdown', 'u-textLeftFlex'),
        panelClassName: classNames({
            'is-closing': isClosing
        }, 'foundationBaseAddon__dropdownPanel'),
        toggleCallback: dropdownToggleCallback,
        iconOverride: (isAddonProductSelected) && isClosed ? removeText() : null,
        displayText: dropdownHtml,
        iconName: 'more',
        dropdownRef,
        isProductSelected: isAddonProductSelected,
        scrollOnOpen: false
    }

    const panelContentContainerClasses = classNames('foundationBaseAddon__panelContentContainer', {
        'foundationBaseAddon__panelContentContainer__foundationTab--frameHidden': hasFoundationTab && activeTab === 0 && !showFrame,
        // furniture pages don't have a footnote
        'foundationBaseAddon__panelContentContainer__foundationTab--noFootnote': hasFoundationTab && activeTab === 0 && !isMattressPdp,
        'foundationBaseAddon__panelContentContainer__foundationTab--frameHidden--noFootnote': hasFoundationTab && activeTab === 0 && !isMattressPdp && !showFrame,
        'foundationBaseAddon__panelContentContainer__baseTab': activeTab === 1 || !hasFoundationTab,
        'foundationBaseAddon__panelContentContainer__baseTab--error': (!hasFoundationTab || activeTab === 1) && baseTabErrorMessage,
        'foundationBaseAddon__panelContentContainer__baseTab--oneTile': (activeTab === 1 && (!showAdjBase || !showAdjBasePlus)) || ((!showAdjBase || !showAdjBasePlus) && !hasFoundationTab),
        'foundationBaseAddon__panelContentContainer__baseTab--oneTile--error': (!hasFoundationTab || activeTab === 1) && !showAdjBase && showAdjBasePlus && baseTabErrorMessage
        // 'foundationBaseAddon__panelContentContainer__solaireKing': (mattressShortName === 'Solaire' && (selectedMattressSize === 'Cal King' || selectedMattressSize === 'King') && activeTab === 0)
    })
    const foundationPanelContentClasses = classNames('foundationBaseAddon__panelContent')

    const adjustableBasePanelContentClasses = classNames('foundationBaseAddon__panelContent', 'foundationBaseAddon__panelContent__baseTab')

    const dropdownPanelOptionClassNames = classNames('foundationBaseAddon__dropdownPanelOption', 'u-padding')

    const buystackErrorMessageClasses = classNames('foundationBaseAddon__buystackErrorMessage', {
        'u-hidden': !buystackErrorMessage
    })

    // --- LABELS ---
    // Creates label for dropdown panel for the foundation, SAB, and SABP
    const createLabel = (productCode, price, valueDiscountAmount = false, label = null) => {
        const priceDisplay = valueDiscountAmount ? <StrikethroughPrice price={price} discountAmount={valueDiscountAmount} /> : ` ${formatCurrency(price)}`
        const labelContainerClasses = classNames('foundationBaseAddon__label', {
            'addonTile__label': activeTab === 1 || !hasFoundationTab
        })
        const labelTextClasses = classNames('foundationBaseAddon__labelText', {
            'addonTile__label__text': activeTab === 1 || !hasFoundationTab
        })
        const priceClasses = classNames('foundationBaseAddon__labelPrice', {
            'addonTile__label__price': activeTab === 1 || !hasFoundationTab
        })

        let labelText = label || ''
        switch (productCode) {
            case ADJ_BASE_CODE:
                labelText = `<a href='/furniture/${productCode}' class='t-link'>${sabName}</a>`
                break
            case ADJ_BASE_PLUS_CODE:
                labelText = `<a href='/furniture/${productCode}' class='t-link'>Saatva Adjustable Base <span class='t-italic t-link'>Plus</span></a>`
                break
            default:
                labelText = label || ''
        }

        return (
            <div className={labelContainerClasses}>
                <div className={labelTextClasses} dangerouslySetInnerHTML={{ __html: labelText }}/>
                {price > 0 && <div className={priceClasses} data-selector={`addonTilePrice_${productCode}`}>{priceDisplay}</div>}
            </div>
        )
    }

    const foundationLabel = createLabel(undefined, foundationPrice, foundationValueDiscountAmount, `Add ${foundationHeight}" ${isHD ? 'HD ' : ''}Foundation, `)

    const toggleModal = (contentKey) => {
        setModalContentName(contentKey)
        setIsModalVisible(true)
    }

    const modalContentMap = {
        mattressFoundationHeights: {
            modalTitle: 'About mattress & foundation heights',
            modalContent: <FoundationModal type="foundation"
            />
        },
        foundationHeights: {
            modalTitle: `About ${isHD ? 'HD ' : ''}foundation heights`,
            modalContent: <FoundationHeights productCode={primaryProductCode} />
        },
        rightBase: {
            modalTitle: 'Choosing the right base for your mattress',
            modalContent: (
                <FoundationBaseInfoModal
                    addonLabel={addonLabel}
                    foundationPrice={foundationPrice}
                    foundationValueDiscountAmount={foundationValueDiscountAmount}
                    framePrice={framePrice}
                    sabpPrice={sabpPrice}
                    sabpValueDiscountAmount={sabpValueDiscountAmount}
                    mattressSize={primarySize}
                    setIsModalVisible={setIsModalVisible}
                />
            )
        }
    }
    const { modalTitle = '', modalContent = undefined } = modalContentMap[modalContentName] || {}
    
    // --- end labels ---

    // --- foundation ---

    const foundationUI = () => {
        let foundationPicture
        if (isMattressPdp) {
            foundationPicture = <LegacyPicture
                lazyLoad={false}
                pictureClassName="foundationBaseAddon__labelImage"
                folder='bases'
                name={'thumb-foundation'}
                alt={'Foundation'}
                type={'png'}
                targetedDevices={[]} />
        } else {
            foundationPicture = <img
                src={`${process.env.NEXT_PUBLIC_ROOT_ASSET_PATH}/product/bedFrames/desktop/2x/foundation_panel@2x.png`}
                alt='Foundation'
                className='foundationBaseAddon__labelImage'/>
        }
        return (
            <div className='foundationBaseAddon____dropdownPanelOption u-padding'>
                <FormCheckbox
                    className="foundationBaseAddon__checkbox"
                    labelClassName="foundationBaseAddon__label"
                    id="Foundation"
                    checked={isFoundationSelected}
                    label={foundationLabel}
                    onChange={() => {
                        if (isFoundationSelected) {
                            toggleFoundation(false)
                        } else {
                            toggleFoundation(true)
                        }
                    }}
                    value="Foundation"
                    data-selector={`foundationBaseAddon-${FOUNDATION_CODE}`}
                    containerAttributes={{ 'data-selector': `foundationBaseAddon-${FOUNDATION_CODE}-container` }}>
                    {foundationPicture}
                </FormCheckbox>

                <div className="foundationBaseAddon__productDescription">
                    Durable hardwood construction provides better support than a box spring.
                    {(isKingOrCalKingSize) &&
                        <div className='u-paddingTop--sm foundationBaseAddon__productDescription__extraNote'>
                            <span className='u-displayBlock t-italic t-disclaimer'> Note: King &#38; Cal King foundations come as a split design for ease of delivery and setup. This does not affect the feel or performance of the foundation.</span>
                        </div>
                    }
                </div>

                {isMattressPdp &&
                    <div className='foundationBaseAddon__chooseHeight'>
                        <div className="foundationBaseAddon__chooseHeightText">
                            Choose height
                            <br />
                            <button className="t-link t-link--action" aria-label='Learn More'
                                onClick={() => {
                                    primaryAttributeSets.mattressHeight
                                        ? toggleModal('mattressFoundationHeights')
                                        : toggleModal('foundationHeights')
                                }}>Learn More</button>
                        </div>
                        <ProductSelectToggle
                            productCode={FOUNDATION_CODE}
                            attributeCode='foundationHeight'
                            displayLabel={false}
                            dataSelector='foundationHeight'
                        />
                    </div>
                }
            </div>
        )
    }
    // --- end foundation ---
    // --- metal frame ---
    const frameLabel = createLabel(undefined, framePrice, undefined, `Add ${frameName}, `)

    const metalFrame = () => {
        return (
            <div className={ classNames('foundationBaseAddon__dropdownPanelOption u-paddingHorizontal u-paddingTop--xs', { 'is-disabled': !isFoundationSelected }) }>
                <FormCheckbox
                    className="foundationBaseAddon__checkbox"
                    labelClassName="foundationBaseAddon__label"
                    id="Frame"
                    checked={isFrameSelected}
                    disabled={!isFoundationSelected}
                    label={frameLabel}
                    onChange={() => {
                        if (isFrameSelected) {
                            toggleFrame(false)
                        } else {
                            toggleFrame(true)
                        }
                    }}
                    value={frameName}
                    data-selector={`foundationBaseAddon-${FRAME_CODE}`}
                    containerAttributes={{ 'data-selector': `foundationBaseAddon-${FRAME_CODE}-container` }}>
                    <LegacyPicture
                        lazyLoad={false}
                        pictureClassName="foundationFrameAddon__labelImage"
                        folder='bases'
                        name={'thumb-frame'}
                        alt={'Frame'}
                        type={'png'}
                        targetedDevices={[]} />
                </FormCheckbox>
                <div className="foundationFrameAddon__productDescription">
                    Steel brace holds foundation and supports mattress. Sits 7.5&#8243; off ground. Mattress cannot sit directly on frame.
                </div>
            </div>
        )
    }

    // --- end metal frame ---
    // --- SAB & SAB+ ---
    // Handles SAB, SABP plus changes only
    const handleBaseChange = (productCode) => {
        if (productCode === ADJ_BASE_CODE) {
            if (isBaseSelected) {
                toggleBase(false)
            } else {
                toggleBase(true)
            }
        } else if (productCode === ADJ_BASE_PLUS_CODE) {
            if (isBasePlusSelected) {
                toggleBasePlus(false)
            } else {
                toggleBasePlus(true)
            }
        }
    }

    const buildAddonTile = (productCode, index = null) => {
        // Do not build adjustable base tile if mattress size is upper flex
        // TODO move to business logic
        if (!showAdjBase && productCode === ADJ_BASE_CODE) return null

        let addonTileProps = {
            parentSku: productCode,
            dropdownPanelClassNames: dropdownPanelOptionClassNames,
            expandConfigFeaturesClasses: 'foundationBaseAddon__adjustableBasePlusFeatures',
            isCheckboxDisabled: false,
            checkboxOnChange: () => handleBaseChange(productCode),
            isSplitSize: isSplitSize,
            isChecked: false,
            checkboxLabel: '',
            tag: false,
            tagLabel: null,
            addonTileSubheaderLabel: 'Featuring:',
            isAdjustableBasePlusOnly: showAdjBasePlus && !showAdjBase
        }

        const adjustableBasePlusUpgradePrice = (sabpPrice - sabpValueDiscountAmount) - sabPrice
        switch (productCode) {
            case ADJ_BASE_CODE:
                addonTileProps.isChecked = isBaseSelected
                addonTileProps.checkboxLabel = createLabel(productCode, sabPrice)
                addonTileProps.isCheckboxDisabled = !includeAdjBase
                break
            case ADJ_BASE_PLUS_CODE:
                addonTileProps.isChecked = isBasePlusSelected
                addonTileProps.checkboxLabel = createLabel(productCode, sabpPrice, sabpValueDiscountAmount)
                addonTileProps.isCheckboxDisabled = !includeAdjBasePlus
                if (showAdjBase) {
                    addonTileProps.tag = Boolean(adjustableBasePlusUpgradePrice) // hide tag when there is no adjustable base option
                    addonTileProps.tagLabel = adjustableBasePlusUpgradePrice ? `Upgrade for $${adjustableBasePlusUpgradePrice} more` : null
                    addonTileProps.addonTileSubheaderLabel = 'Additional Features:'
                }
        }

        return <RelatedProductDropdownPanelAddonTile key={index} {...addonTileProps}/>
    }
    // --- end SAB & SAB+ ---

    // --- learn more modal ---

    const learnMoreClasses = classNames('foundationBaseAddon__learnMoreToggle t-link t-link--independent t-link--action u-textRightFlex')

    const learnMoreButton = () => {
        if (isMattressPdp && showFoundation && showFrame && showAdjBasePlus) {
            return (
                <button
                    className={learnMoreClasses}
                    onClick={() => toggleModal('rightBase')}>
                    Learn more
                </button>
            )
        } else {
            return null
        }
    }

    // --- end learn more modal

    // --- includedAddonList for upperFlex Options

    const getIncludedAddonList = () => {
        if (primarySize === 'Upper-Flex Queen' || primarySize === 'Upper-Flex King') {
            const addonItems = [
                {
                    html: <span>Pairs with Upper-Flex <b className="t-fontWeight--normal">{sabpName}</b></span>,
                    isSelected: isBasePlusSelected
                },
                {
                    html: <span><strong className="t-fontWeight--normal">FREE</strong> Custom Fitted Upper-Flex Sheet Set ({formatCurrency(sheetPrice)} value)</span>,
                    isSelected: true
                }
            ]
            return <IncludedAddonList titleText={primarySize} addonItems={addonItems} />
        } else {
            return null
        }
    }

    const onModalClose = () => {
        setModalContentName('')
        setIsModalVisible(false)
    }

    // --- end includedAddonList

    return (
        <>
            {getIncludedAddonList()}
            {learnMoreButton()}
            <div className={buystackErrorMessageClasses} dangerouslySetInnerHTML={{ __html: buystackErrorMessage }} />
            <DropdownPanel {...dropdownProps}>
                {tabs}
                <div className={panelContentContainerClasses}>
                    {hasFoundationTab &&
                        <div ref={foundationPanel} className={foundationPanelContentClasses}>
                            {foundationUI()}
                            {showFrame && metalFrame()}
                            {footnote()}
                        </div>
                    }
                    {hasBaseTab &&
                        <div ref={adjustableBasesPanel} className={adjustableBasePanelContentClasses}>
                            {[ADJ_BASE_CODE, ADJ_BASE_PLUS_CODE].map((productCode, i) => {
                                return buildAddonTile(productCode, i)
                            })}
                            {baseTabErrorMessage &&
                                <div className='foundationBaseAddon__adjustableBasePlusError'>
                                    {baseTabErrorMessage}
                                </div>
                            }
                        </div>
                    }
                </div>
            </DropdownPanel>
            <Modal
                type='popup'
                isModalVisible={isModalVisible}
                onModalClose={onModalClose}
                modalTitle={modalTitle}
                modalLabel='foundation-base-addon-modal'
            >
                {modalContent}
            </Modal>
        </>
    )
}

export default FoundationBaseAddon
