import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import { ProductCarousel } from '@saatva-bits/pattern-library.components.product-carousel'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import { useProductState, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { filterARImages } from '@saatva-bits/pattern-library.utils.product'
import logger from '@saatva-bits/pattern-library.utils.logger'

import { SAATVA_CLASSIC } from '@/constants/product-codes'
import { BED_FRAMES, PLATFORM_BASES } from '@/constants/subcategories'
import useDeviceType from '@/hooks/useDeviceType'
import { createImgixVideo, createImgixVideoThumbnail, getArdadImages } from '@/utils/pdpCarousel'

import videoConfig from '@/components/ProductCarouselGallery/carouselVideoConfig'
import ProductCarouselGallery from '@/components/ProductCarouselGallery'
import EpigraphModelViewer from '@/components/EpigraphModelViewer'
import AffiliateBadge from '@/components/AffiliateBadge'

import styles from './ProductCarouselWrapper.module.scss'

const ProductCarouselWrapper = ({
    productCode
}) => {
    const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

    const { isDesktop, deviceType } = useDeviceType('desktop')
    const productState = useProductState(productCode, [])
    const { options, content } = useProductData(productCode)
    const { isOff: showWrappedThumbs } = useFeatureFlag('DIS_CAROUSEL_WRAP.EX-489')

    // TODO: Refactor to avoid displaying image resizing in Tablet view. (DeviceType is no totally reliable to identfy real tablets since the user-agent for most tablets is "desktop" now)
    const [isLandscape, setIsLandscape] = useState(false)
    const isMobileOrTablet = !isDesktop
    const isBedFrameProduct = [BED_FRAMES, PLATFORM_BASES].includes(productState?.subcategory)

    // Centralized aspect ratio configuration.
    // Could be moved to utils/pdpCarousel.js if we want to use this in other components.
    const aspectRatios = {
        hero: {
            default: '3-2',
            zoom: {
                desktop: {
                    portrait: '16-9',
                    landscape: '16-9',
                },
                tablet: {
                    portrait: '4-3',
                    landscape: '16-9',
                },
                mobile: {
                    portrait: '3-2',
                    landscape: '16-9',
                }
            }
        },
        thumbnail: {
            desktop: '3-2',
            tablet: '1-1',
            mobile: '1-1',
            zoom: '3-2',
        },
        video: {
            mobileOrTablet: '3-2',
        }
    }

    const getAspectRatio = (type, isZoomGallery = false) => {
        if (type === 'hero') {
            if (!isZoomGallery) {
                return aspectRatios.hero.default
            }
            
            // Handle zoom gallery hero images
            return isLandscape 
                ? aspectRatios.hero.zoom[deviceType].landscape
                : aspectRatios.hero.zoom[deviceType].portrait
        }
        
        if (type === 'thumbnail') {
            if (isZoomGallery) {
                return aspectRatios.thumbnail.zoom
            }
            
            return aspectRatios.thumbnail[deviceType] 
        }
        
        return aspectRatios.hero.default // fallback
    }

    const createAssetDrivenImages = (productCode, productState, isZoomGallery = false) => {
        const filterARImagesOut = isMobileOrTablet && (isBedFrameProduct && !isZoomGallery)
        const heroAspectRatios = getAspectRatio('hero', isZoomGallery)
        const thumbnailAspectRatios = getAspectRatio('thumbnail', isZoomGallery)
        let heroImages = []
        let thumbnailImages = []

        const ardadDescriptors = content?.ardadDescriptors
        const carouselDescriptors = ardadDescriptors?.find( descriptorDef => descriptorDef.slot == 'carousel')
        logger.debug({
            message: 'Carousel descriptors found in prismic',
            location: 'components.ProductCarouselWrapper.createAssetDrivenImages',
            details: {
                descriptors: carouselDescriptors.descriptors
            }
        })

        heroImages = getArdadImages(productState, carouselDescriptors.descriptors, heroAspectRatios, isZoomGallery)
        thumbnailImages = getArdadImages(productState, carouselDescriptors.descriptors, thumbnailAspectRatios, isZoomGallery)

        // filter video from classic on desktop
        if (productCode === SAATVA_CLASSIC && (isDesktop && !showWrappedThumbs)) {
            heroImages = filterSaatvaClassicVideo(heroImages)
            thumbnailImages = filterSaatvaClassicVideo(thumbnailImages)
        }

        heroImages = filterARImages(heroImages, filterARImagesOut)
        thumbnailImages = filterARImages(thumbnailImages, filterARImagesOut)

        const altTag = productState?.genericName
        const imgixOptionsOverrides = !isZoomGallery && { auto: 'format,compress' }

        return {
            heroImages,
            thumbnailImages,
            altTag,
            imgixOptionsOverrides
        }
    }

    const [assetDrivenImages, setAssetDrivenImages] = useState(createAssetDrivenImages(productCode, productState))

    useEffect(() => {
        setAssetDrivenImages(createAssetDrivenImages(productCode, productState))
        if (window) {
            setIsLandscape(window.matchMedia('(orientation: landscape)').matches)
        }
    }, [productState, isMobileOrTablet])

    const productZoomGalleryData = createAssetDrivenImages(productCode, productState, true)

    if (!assetDrivenImages) return null // short circuit if no images

    const videoDataConfig = videoConfig[productCode]
    let videoData = {}
    if (videoDataConfig) {

        if (showWrappedThumbs) {
            videoDataConfig.aspectRatio = getAspectRatio('hero')
        } else if (isMobileOrTablet) {
            videoDataConfig.aspectRatio = aspectRatios.video.mobileOrTablet
        }

        const imgxVideoThumbnail = createImgixVideoThumbnail(videoDataConfig, undefined, '', true, true, getAspectRatio('thumbnail'))
        const imgxVideo = createImgixVideo(videoDataConfig, videoDataConfig.carouselIndex, 'product-carousel-video')
        videoData = {
            videoThumbnail: imgxVideoThumbnail,
            videoChild: imgxVideo
        }
    }

    const thumbnailsClasses = classNames(styles.thumbnailsWrapper, {
        [styles['thumbnailsWrapper--no-wrap']]: !showWrappedThumbs,
        [styles['thumbnailsWrapper--wrap']]: showWrappedThumbs
    })

    // Return both carousels on the server side and let CSS handle display based on resolution
    // This avoids flash of desktop carousel on mobile/tablet and reduces layout shift for EX-134 test.
    return (
        <ProductCarouselGallery
            arChild={<EpigraphModelViewer productCode={productCode}/>}
            imgixDomain={IMGIX_URL}
            productCode={productCode}
            productImageData={productZoomGalleryData}
        >
            <ProductCarousel
                productImageData={assetDrivenImages}
                sliderContainerClasses={styles.containerBreakout}
                imgixDomain={IMGIX_URL}
                arChild={<EpigraphModelViewer productCode={productCode}/>}
                productState={productState}
                productOptions={options}
                aspectRatio={aspectRatios.hero.default}
                productCode={productCode}
                alwaysShowThumbnails
                showHighlight={!isDesktop}
                videoData={videoData}
                thumbnailsClasses={thumbnailsClasses}
                overflow='hidden'
            >
                <AffiliateBadge productCode={productCode} />
            </ProductCarousel>
        </ProductCarouselGallery>
    )
}

const filterSaatvaClassicVideo = (assets) => {
    const videoDescriptor = 'video'

    return assets.filter(asset => {
        if (asset.filename.includes(videoDescriptor)) {
            return false
        }
        return true
    })
}

ProductCarouselWrapper.propTypes = {
    productCode: string
}

export default ProductCarouselWrapper
