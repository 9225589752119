import { findClosestVariant, getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const formatName = (name, addBundleInName = false) => {
    const pattern = /^(.*?)((\s[in])|[,])+/g
    const returnPattern = pattern.exec(name)

    if (!returnPattern) return name
    return addBundleInName ? `${returnPattern[1]}, 2-pack` : returnPattern[1]
}

const findSelectedVariant = (product, productOverrides) => {
    // The selectedVariant tells us the default state of the product and lets us do the best guess
    // at choosing the correct image to preload. This should produce a correct default in >95% of cases,
    // but storing selected state in localStorage means the following products might not have the correct hero preloaded:
    // zenhaven, solaire, dog-bed, foundation, plush-towels, waffle-towels
    // It's limited to products where the first image varies based on a non-configurable attribute such as mattressType
    // or where the first image varies based on size that could be different on the client side.
    let selectedVariant

    if (productOverrides?.skuQuery) {
        // defaults to empty object because missing variant would return undefined, breaking generateAssetURLs later
        selectedVariant = product.variants.find(variant => variant.sku === productOverrides.skuQuery) || {}
    } else {
        let defaultAttributes = {}
        const { variants, configurableAttributes, options } = product
        options.forEach(option => { defaultAttributes[option.code] = option.default }) // reformats into a list of key/value pairs
        selectedVariant = findClosestVariant(variants, configurableAttributes, defaultAttributes)
    }
    return selectedVariant
}

// Build OpenGraph social sharing tag
const buildOpenGraphTag = (data) => {
    try {
        const {
            productCode,
            category,
            title,
            description,
            products,
            selectionContextProductOverrides
        } = data
        const currentProduct = products[0]
        const carouselDescriptors = currentProduct?.content?.ardadDescriptors?.find((descriptor) => descriptor.slot === 'carousel')?.descriptors || []

        const overrides = selectionContextProductOverrides[productCode]
        const selectedVariant = findSelectedVariant([currentProduct], overrides)
        const { folder, file: filename } = getArdadAssetFromVariant(selectedVariant, carouselDescriptors[0], '16-9') 
        if (!folder || !filename) return null

        const image = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${folder}/${filename}?w=1200&fit=crop&auto=format`
        const url = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}/${category}/${productCode}`

        return {
            title, // og:title currently the meta title, could also be product name
            description, // og:description
            type: 'product', // og:type
            locale: 'us', // og:locale 
            image, // og:image
            url, // og:url
        }
    } catch (error) {
        const { productCode, category } = data
        console.error(`[pages.product.buildOpenGraphTag] Could not create OpenGraph tags for product ${productCode} in category ${category}`)
        return null
    }
}

const getArdadAssetFromVariant = (variant, descriptor, ratio) => {
    let asset = { folder: null, file: null, altTag: null }
    try {
        asset = getAssetForVariant(variant, descriptor, ratio)
    } catch (error) {
        logger.error({
            message: 'Cannot find ARDAD asset for variant',
            location: 'utils/products.js',
            error,
            details: {
                productCode: variant.productCode,
                sku: variant.sku,
                descriptor
            },
            enableClientAlerts: true,
        })
    }

    return asset
}


const getDetailTileArdadImages = (
    ardadDescriptors,
    selectedVariant,
    ratio = '3-2',
    imageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        lazyLoadOffset: null // Override the lazyload offset added by the detail product tile from bit
    }
) => {
    const [ardadDefaultImage, ardadHoverImage] = ['product-tile', 'product-tile-hover'].map(slot => {
        const descriptor = ardadDescriptors.find(descriptorDef => descriptorDef.slot == slot).descriptors
        const { folder, file } = getArdadAssetFromVariant(selectedVariant, descriptor[0], ratio)
        return {
            ...imageProps,
            folder,
            name: file,
            alt: selectedVariant.genericName,
            prefixOverride: {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            },
            imgixDomain: IMGIX_URL
        }
    })

    return {
        defaultImage: ardadDefaultImage,
        hoverImage: ardadHoverImage
    }
}

export {
    buildOpenGraphTag,
    findSelectedVariant,
    formatName,
    getDetailTileArdadImages
}
