import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const Context = createContext()

const LearnMoreModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <Context.Provider value={{ isModalOpen, setIsModalOpen }}>
            { children }
        </Context.Provider>
    )
}

LearnMoreModalProvider.propTypes = {
    children: PropTypes.any
}

const useLearnMoreModalContext = () => {
    const learnMoreModalContext = useContext(Context)

    if (typeof learnMoreModalContext === 'undefined') {
        throw new Error('useLearnMoreModalContext must be used within LearnMoreModalProvider')
    }

    return learnMoreModalContext
}

export {
    LearnMoreModalProvider,
    useLearnMoreModalContext
}
