import React, { useState, useContext, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { FavoriteIcon } from '@saatva-bits/pattern-library.modules.favorite'
import { Global } from '@/contexts'
import { useProductData, getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './AddToFavoritesPanel.module.scss'
import logger from '@saatva-bits/pattern-library.utils.logger'

// Favorites only stores the product, not the variant, so use the images from the default variant
const getThumbnailImage = (productCode, variant, ardadDescriptors) => {
    try {
        const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL
        const carouselDescriptors = ardadDescriptors?.find((descriptor) => descriptor.slot === 'carousel')?.descriptors || []
        const productImage = getAssetForVariant(variant, carouselDescriptors[0], '1-1') || {}
        const { folder, file: filename } = productImage

        return {
            folder,
            name: filename,
            alt: variant.genericName,
            prefixOverride: {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            },
            imgixDomain: IMGIX_URL
        }
    } catch (error) {
        logger.warn({
            message: `Could not get assetForVariant for product ${productCode} using variant ${variant.sku}`,
            location: 'AddToFavoritesPanel.getThumbnailImage',
            details: {
                productCode: productCode,
                sku: variant.sku
            }
        })
        return null
    }
}


/**
 * A custom hook that implements draggable panel functionality with touch gestures
 * 
 * @param {number} threshold - Distance in pixels the panel must be dragged down to trigger dismissal (default: 50px)
 * @returns {Object} An object containing:
 *   - translateY: Current vertical translation value
 *   - isVisible: Whether the panel should be rendered
 *   - isClosing: Whether the panel is in closing animation state
 *   - close: Function to programmatically close the panel
 *   - handlers: Touch event handlers to attach to the panel element
 */
const useDraggablePanel = (threshold = 50) => {
    // Track touch gesture state
    const touchStartY = useRef(0) // Initial Y position when touch starts
    const currentTranslateY = useRef(0) // Current translation during drag
    const isDragging = useRef(false)
    
    // Panel state
    const [translateY, setTranslateY] = useState('0px')
    const [isVisible, setIsVisible] = useState(true)
    const [isClosing, setIsClosing] = useState(false)

    // Handle closing animation sequence
    useEffect(() => {
        if (isClosing) {
            setTranslateY('100vh')
            const timer = setTimeout(() => {
                setIsVisible(false)
                setIsClosing(false)
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [isClosing])

    const handleTouchStart = (e) => {
        touchStartY.current = e.touches[0].clientY
        isDragging.current = true
    }

    const handleTouchMove = (e) => {
        if (!isDragging.current) return

        // touches[0] represents the first touch point in a touch event
        // For single-finger gestures, this gives us the Y coordinate of where the finger is touching the screen
        const currentY = e.touches[0].clientY
        const deltaY = currentY - touchStartY.current

        // Constrain drag range: -250px up (spring back) to 200px down (dismiss)
        const newTranslateY = Math.min(Math.max(deltaY, -250), 200)
        currentTranslateY.current = newTranslateY
        setTranslateY(`${newTranslateY}px`)
    }

    const handleTouchEnd = () => {
        isDragging.current = false

        // If dragged down past threshold, trigger closing animation
        if (currentTranslateY.current > threshold) {
            close()
        } else {
            // Spring back to original position
            setTranslateY('0px')
        }
    }

    const close = () => {
        setIsClosing(true)
    }

    return {
        translateY,
        isVisible,
        isClosing,
        close,
        handlers: {
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove,
            onTouchEnd: handleTouchEnd
        }
    }
}

/**
 * AddToFavoritesPanel Component
 * 
 * A bottom sheet modal that allows users to add products to their favorites.
 * Features:
 * - Draggable panel with spring-back animation
 * - Swipe-to-dismiss functionality
 * - Backdrop overlay with click-to-dismiss
 */
const AddToFavoritesPanel = () => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { name, category, subcategory, url, defaultVariant, content } = useProductData(productCode)
    const thumbnailImage = getThumbnailImage(productCode, defaultVariant, content.ardadDescriptors)
    const panelRef = useRef(null)
    const { isMobile } = useDeviceType('mobile')

    const {
        translateY,
        isVisible,
        isClosing,
        close,
        handlers
    } = useDraggablePanel()

    // Prevent body scrolling when panel is visible
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden'
            document.body.style.position = 'fixed'
            document.body.style.width = '100%'
        }

        return () => {
            document.body.style.overflow = 'auto'
            document.body.style.position = ''
            document.body.style.width = ''
        }
    }, [isVisible])

    // SSR check - don't render if window is not available or if the user is not on mobile
    if (typeof window === 'undefined' || !isMobile) {
        return null
    }

    const classes = classNames(styles.favoriteIcon)
    const panelClasses = classNames(
        styles.addToFavoritesPanel,
        styles.draggablePanel,
        {
            [styles.closing]: isClosing
        }
    )

    const overlayClasses = classNames(styles.overlay, {
        [styles.overlayClosing]: isClosing
    })

    const modal = (
        <>
            {/* Backdrop overlay */}
            <div
                className={overlayClasses}
                onClick={close}
                aria-hidden="true"
            />
            {/* Panel container */}
            <div
                ref={panelRef}
                className={panelClasses}
                role="dialog"
                aria-modal="true"
                aria-label="Add to Favorites"
                tabIndex={-1}
                style={{
                    transform: `translateY(${translateY})`
                }}
                {...handlers}
                data-selector={'qr-modal-favorites-modal'}
            >
                <div>
                    {/* Drag handle */}
                    <div className={styles.slideBar} />
                    {/* Panel content */}
                    <p className={styles.text}>
                        Need more time to explore this Saatva mattress later?
                        <br />
                        Save it to your <a href={'/favorites/'}>Favorites</a> while you take a look around.
                    </p>
                    <FavoriteIcon
                        productCode={productCode}
                        className={classes}
                        data-selector="qr-modal-favorite-icon"
                        title={{ text: name, href: url }}
                        location="QR Code Modal on PDP"
                        category={category}
                        subCategory={subcategory}
                        thumbnailImage={thumbnailImage}
                        onClick={close}
                    />
                </div>
            </div>
        </>
    )

    // Using React Portal to render the modal directly into document.body
    // This ensures the modal appears above all other content regardless of DOM hierarchy
    // and avoids z-index/stacking context issues that could occur if rendered in the normal flow
    return isVisible && createPortal(modal, document.body)
}

export default AddToFavoritesPanel
