import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import DetailProductTileSlide from '@/components/CrossSells/partials/DetailProductTileSlide/DetailProductTileSlide'
import styles from './DetailProductTileSlider.module.scss'

const DetailProductTileSlider = ({
    title,
    products,
    sectionClassName,
    titleClassName,
    dotsClassName,
    tilesClassName,
    leftButtonClassName,
    rightButtonClassName,
    dataSelector,
    isCompact = false,
    mobilePageDots = false,
    cartType = false,
    useSwatches = false
}) => {
    if (!products || products.length === 0) {
        return null
    }

    const sectionClasses = classNames('section', sectionClassName)
    const titleClasses = classNames('t-heading2', styles.title, titleClassName)
    const tileClasses = classNames(styles.tile, tilesClassName, {
        [styles.compactTile]: isCompact,
    })
    const leftButtonClasses = classNames(styles.buttons, styles.leftButton, leftButtonClassName)
    const rightButtonClasses = classNames(styles.buttons, styles.rightButton, rightButtonClassName)

    const SliderContent = products.map(
        (product) => <DetailProductTileSlide
            key={product.sku}
            product={product}
            tileClasses={tileClasses}
            useSwatches={useSwatches}
            hideReviewStars={isCompact ? true : false}
            ctaText={isCompact ? 'Shop now' : null}
        />
    )

    // We don't need SimpleSlider for compact mode (where we only show two products)
    if (isCompact) {
        return (
            <>
                {title && <h2 className={titleClasses}>{title}</h2>}
                <div className={styles.compactWrapper}>
                    { SliderContent }
                </div>
            </>
        )
    }

    const showMultiplePages = products.length > 3

    const sliderProps = {
        sliderLabel: dataSelector,
        infinite: showMultiplePages,
        showButtons: showMultiplePages,
        pageDots: showMultiplePages,
        dotsClassName: dotsClassName,
        leftButtonClassName: leftButtonClasses,
        rightButtonClassName: rightButtonClasses,
        onChange: () => {
            // refreshes the Yotpo review stars for infinite slides
            try {
                window.yotpo.initWidgets()
            } catch (error) {
                console.error(error)
                window.ineum?.(`[YotpoReviewStars] ${error}`)
            }
        }
    }

    return (
        <section className={sectionClasses} data-selector={dataSelector}>
            <div className="container">
                { title && <h2 className={titleClasses}>{title}</h2> }
                <div className={styles.wrapper}>
                    <div className={styles.mobileSlider}>
                        <SimpleSlider
                            shownSize={1}
                            overflow={'visible'}
                            pageDots={mobilePageDots}
                            pageDotsAndNumbers={showMultiplePages}
                            isCart={cartType}
                            {...sliderProps}
                        >
                            { SliderContent }
                        </SimpleSlider>
                    </div>
                    <div className={styles.defaultSlider}>
                        <SimpleSlider
                            shownSize={3}
                            overflow={'hidden'}
                            pageDotsAndNumbers={showMultiplePages}
                            isCart={cartType}
                            {...sliderProps}
                        >
                            { SliderContent }
                        </SimpleSlider>
                    </div>
                </div>
            </div>
        </section>
    )
}

DetailProductTileSlider.propTypes = {
    title: PropTypes.string,
    dataSelector: PropTypes.string.isRequired,
    sectionClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    dotsClassName: PropTypes.string,
    tilesClassName: PropTypes.string,
    leftButtonClassName: PropTypes.string,
    rightButtonClassName: PropTypes.string,
    /** Override to show the pagination dots even on mobile */
    mobilePageDots: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        priceRange: PropTypes.shape({
            lowestPrice: PropTypes.number,
            highestPrice: PropTypes.number
        }),
        image: PropTypes.shape({
            folder: PropTypes.string,
            name: PropTypes.string,
            alt: PropTypes.string
        }),
        sku: PropTypes.string,
        category: PropTypes.string
    })).isRequired,
    useSwatches: PropTypes.bool,
    isCompact: PropTypes.bool
}

export default DetailProductTileSlider
