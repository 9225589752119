import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { AffirmMessaging } from '@saatva-bits/pattern-library.components.affirm-messaging'
import { affirmRefresh } from '@/utils/affirm'
import useProductPricingDetails from '@/hooks/useProductPricingDetails'
import styles from './AffirmMessage.module.scss'

const AffirmMessage = ({ productCode, className }) => {
    const { finalPrice } = useProductPricingDetails(productCode)
    const affirmPrice = finalPrice
    const showAffirmMessage = affirmPrice > 0

    useEffect(() => {
        affirmRefresh()
    }, [affirmPrice])

    const classes = classNames(styles.subtotal__affirmMessaging, className, 'affirm-as-low-as u-marginBottom')

    let attributeData = {
        'class': classes,
        'data-amount': affirmPrice * 100,
        'data-page-type': 'product'
    }

    if (productCode) {
        attributeData['data-sku'] = productCode
    }

    const displayMessage = showAffirmMessage ? <AffirmMessaging
        htmlAttributes={attributeData}
    ></ AffirmMessaging>
        : null

    return displayMessage
}

AffirmMessage.propTypes = {
    productCode: PropTypes.string
}

export default AffirmMessage
