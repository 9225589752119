import React, { useState } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { Badge } from '@saatva-bits/pattern-library.components.badge'

import useProductPricingDetails from '@/hooks/useProductPricingDetails'
import { CLASSIC_STARTER_BUNDLE } from '@/constants/product-codes'
import styles from './ProductSubtotal.module.scss'
import { MAP_PROMO_COLOR_TO_BADGE } from '@/constants/colors'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import DiscountLabels from '@/components/DiscountLabels/DiscountLabels'
import { Icon } from '@saatva-bits/pattern-library.components.icon'

const ProductSubtotal = ({ productCode }) => {
    const { isV1: showDiscountLabels } = useExperiment('EXP.PROMOTIONS_MSGING.EX-450')
    const [isDetailsVisible, setIsDetailsVisible] = useState(true)

    const {
        fullPrice,
        finalPrice,
        discountAmount,
        sitewidePromo,
        inStock,
        labelBreakdown
    } = useProductPricingDetails(productCode)

    let hasDiscountAmount = (Array.isArray(labelBreakdown) && labelBreakdown.length > 0) ? true : false

    let showDiscountLabelToggle = hasDiscountAmount && showDiscountLabels

    const sitewideTheme = sitewidePromo?.theme
    const badgeColor = sitewideTheme && MAP_PROMO_COLOR_TO_BADGE[sitewideTheme] 
        ? MAP_PROMO_COLOR_TO_BADGE[sitewideTheme] 
        : MAP_PROMO_COLOR_TO_BADGE.blue

    const showStrikethroughPrice = fullPrice !== finalPrice

    // We only show the promo pill for discounts on the Classic Starter Bundle.
    // This is also needed to adjust mobile styles and font sizes on that product.
    const hasPromoPill = productCode === CLASSIC_STARTER_BUNDLE && discountAmount > 0 && !showDiscountLabels

    const renderStrikethrough = () => {
        const strikethroughClasses = classNames(styles.strikethroughPrice, 'u-marginRight--sm', {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })
        return (
            showStrikethroughPrice &&
            <span
                data-selector="product-subtotal-price"
                className={strikethroughClasses}
            >
                {formatCurrency(fullPrice)}
            </span>
        )
    }

    if (Boolean(finalPrice) && inStock) {
        const subtotalClasses = classNames(styles.text, {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })
        const finalPriceClasses = classNames(styles.finalPrice, {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })

        const discountLabelClasses = classNames(styles.discountLabels, {
            [styles.discountLabelsVisible]: isDetailsVisible
        })

        return (
            <>
                <div className={`${styles.subtotal} u-fullWidth`}>
                    <div className={styles.subtotalLeft}>
                        <span className={subtotalClasses}>Subtotal</span>
                        {showDiscountLabelToggle && (
                            <button
                                className={styles.toggleButton}
                                onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                                aria-label="Toggle discount details"
                                aria-expanded={isDetailsVisible}
                            >
                                <span className={styles.toggleText}>
                                    {isDetailsVisible ? 'hide savings' : 'view savings'}
                                </span>
                                <Icon
                                    name='expand'
                                    className={classNames(styles.chevron, { [styles.chevronRotated]: isDetailsVisible })}
                                    alt='Toggle Discount Details'
                                    description='Toggle Discount Details'
                                    titleId='toggleDiscountDetails' />
                            </button>
                        )}
                    </div>
                    <div>
                        {hasPromoPill &&
                            <Badge 
                                className={styles.badge} 
                                text={`Save ${formatCurrency(discountAmount)}`} 
                                kind={badgeColor} 
                                position="inline" 
                            />
                        }
                        {renderStrikethrough()}
                        <span
                            data-selector="product-total-price"
                            className={finalPriceClasses}>
                            {formatCurrency(finalPrice)}
                        </span>
                    </div>
                </div>
                {showDiscountLabels && (
                    <div className={discountLabelClasses}>
                        <DiscountLabels productCode={productCode} showDiscountAmount={true} includeAddons={true} />
                    </div>
                )}
            </>
        )
    }
    
    return null
}

ProductSubtotal.propTypes = {
    productCode: string
}

export default ProductSubtotal

